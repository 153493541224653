import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import MapComponent from "./../Elements/map";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Testimonials3 from "../Elements/Testimonials3";
// var locations = require("./../../images/banner/10.jpg");

var locations = require("./../../images/map.gif");

var bnrimg = require("./../../images/banner/contactus11.png");

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      username: "",
      email: "",
      phone: "",
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name === "username") {
      const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
      this.setState({ [name]: filteredValue });
    } else if (name === "phone") {
      const filteredValue = value.replace(/[^0-9]/g, "");
      this.setState({ [name]: filteredValue });
    } else {
      this.setState({ [name]: value });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { username, email, phone, message } = this.state;

    // Additional client-side validation
    if (!/^[a-zA-Z\s]+$/.test(username)) {
      toast.error("Invalid username! Only letters and spaces are allowed.");
      return;
    }

    const data = { username, email, phone, message };
    this.setState({ loading: true });

    try {
      const response = await fetch(
        "https://cheranmachines.zerame.com/api/form.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("Form submission successful:", responseData);
        toast.success("Form submission successful!");
        this.setState({
          username: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        console.error("Form submission failed:", response.statusText);
        toast.error("Form submission failed!");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form!");
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const mobileView = window.innerWidth <= 768;
    const bannerStyle = {
      height: "240px",

      display: "table",
      width: "100%",
      backgroundImage: `url(${bnrimg})`,
      backgroundSize: "cover",
      backgroundPosition: mobileView ? "82% " : "center center",
    };
    const { loading, username, email, phone, message } = this.state;

    return (
      <>
        <div className="application">
          <Helmet>
            <meta
              property="og:url"
              content="https://cheranmachines.zerame.com/contactus"
            />
            <meta name="twitter:card" content="twitter content" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Contact Cheran Machines" />
            <meta property="og:description" content="contactus" />
            <meta
              property="og:image"
              content="https://cheranmachines.zerame.com/favicon.png"
            />
            <meta property="og:image:alt" content="Cheran Machines Logo" />
          </Helmet>
        </div>
        <Header />
        <div className="page-content">
          <div style={{ height: "50% !important" }}>
            <Banner
              title="Putting a plan to action to assure your satisfaction!"
              pagename="Contact Us"
              bgimage={bnrimg}
              bannerstyle={bannerStyle}
            />
          </div>

          <div className="section-full p-t40 inner-page-padding inner-page-padding-mob">
            <div className="container">
              <div className="section-content">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <form
                      className="contact-form cons-contact-form"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="contact-one m-b30">
                        <div className="section-head">
                          <div className="mt-separator-outer separator-center mob-getin">
                            <div className="mt-separator mt-separator-contact">
                              <h2 className="text-uppercase sep-line-one font-weight-900 ">
                                <span className="font-weight-900 text-primary">
                                  Get
                                </span>{" "}
                                In touch
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            name="username"
                            type="text"
                            required
                            className="form-control"
                            minLength="3"
                            maxLength="20"
                            pattern="[a-zA-Z\s]*"
                            onChange={this.handleInputChange}
                            placeholder="Name"
                            value={username}
                            disabled={loading}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="email"
                            type="email"
                            maxLength="60"
                            className="form-control"
                            required
                            placeholder="Email"
                            onChange={this.handleInputChange}
                            value={email}
                            disabled={loading}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="phone"
                            type="tel"
                            maxLength="10"
                            className="form-control"
                            onChange={this.handleInputChange}
                            required
                            placeholder="Phone"
                            value={phone}
                            disabled={loading}
                            aria-label="Phone"
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            name="message"
                            rows={4}
                            className="form-control"
                            required
                            minLength="5"
                            placeholder="Message"
                            onChange={this.handleInputChange}
                            value={message}
                            disabled={loading}
                          />
                        </div>
                        <div className="text-right">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button btn-effect"
                            disabled={loading}
                            style={{ marginTop: "10px" }}
                          >
                            {loading ? "Submitting..." : "Submit"}
                          </button>
                        </div>
                      </div>
                    </form>
                    {loading && (
                      <div className="loader">
                        <p>Loading...</p>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="contact-info m-b30">
                      <div className="section-head mob-getin-con">
                        <div className="mt-separator-outer separator-center">
                          <div className="mt-separator mt-separator-contact">
                            <h2 className="text-uppercase sep-line-one font-weight-900">
                              <span className="font-weight-900 text-primary">
                                Contact
                              </span>{" "}
                              Info
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div
                        className="p-a20 text-white"
                        style={{
                          backgroundColor: "rgb(238,238,238)",
                          color: "black",
                        }}
                      >
                        <div className="mt-icon-box-wraper left p-b9">
                          <div className="icon-xs">
                            <i className="fa fa-phone" />
                          </div>
                          <div className="icon-content">
                            <h5
                              className="m-t0 font-weight-500"
                              style={{ color: "black" }}
                            >
                              Phone Number
                            </h5>
                            <a
                              style={{ color: "black" }}
                              href="tel:+919843912054"
                              className="phone-item"
                            >
                              +91 9843912054
                            </a>
                            <br />
                            {/* <a
                              style={{ color: "black" }}
                              href="tel:+919843912054"
                              className="phone-item"
                            >
                              +91 9843912054
                            </a>
                            <br />
                            <a
                              style={{ color: "black" }}
                              href="tel:+919843912054"
                              className="phone-item"
                            >
                              +91 9843912054
                            </a>
                            <br />
                            <a
                              style={{ color: "black" }}
                              href="tel:+919843912054"
                              className="phone-item"
                            >
                              +91 9843912054
                            </a>
                            <br />
                            <a
                              style={{ color: "black" }}
                              href="tel:+919843912054"
                              className="phone-item"
                            >
                              +91 9843912054
                            </a> */}
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper left p-b9">
                          <div className="icon-xs">
                            <i className="fa fa-envelope" />
                          </div>
                          <div className="icon-content">
                            <h5
                              className="m-t0 font-weight-500"
                              style={{ color: "black" }}
                            >
                              Email Address
                            </h5>
                            <a
                              style={{ color: "black" }}
                              href="mailto:marketing@cheranmachines.com"
                              className="phone-item"
                            >
                              marketing@cheranmachines.com
                            </a>
                            {/* <br />
                            <a
                              style={{ color: "black" }}
                              href="mailto:marketing@cheranmachiens.com"
                              className="phone-item"
                            >
                              marketing@cheranmachiens.com
                            </a>
                            <br />
                            <a
                              style={{ color: "black" }}
                              href="mailto:marketing@cheranmachiens.com"
                              className="phone-item"
                            >
                              marketing@cheranmachiens.com
                            </a> */}
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper left p-b10">
                          <div className="icon-xs">
                            <i className="fa fa-map-marker" />
                          </div>
                          <div className="icon-content">
                            <h5
                              className="m-t0 font-weight-500"
                              style={{ color: "black" }}
                            >
                              Head Office
                            </h5>
                            <a
                              href="https://maps.app.goo.gl/51Rp52FvhegRPZY77?g_st=aw"
                              target="_blank"
                              className="phone-item"
                              style={{ paddingRight: "2%", color: "black" }}
                            >
                              142/3, Bharathi Street, Udayampalayam Main Road,
                              Chinnavedampatty Post Coimbatore - 641049. Tamil
                              Nadu, India.
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Testimonials3 />
                {/* <div className="section-head section-contact-head">
                  <div
                    className="mt-separator-outer separator-center"
                    style={{ paddingTop: "30px" }}
                  >
                    <div className="mt-separator">
                      <h2 className="text-uppercase sep-line-one font-weight-900">
                        <span className="font-weight-900 text-primary">
                          Our
                        </span>{" "}
                        Network
                      </h2>
                    </div>
                  </div>
                  <img className="network-img" src={locations} alt="" />
                </div> */}
                <div className="section-head section-contact-head">
                  <div className="mt-separator-outer separator-center">
                    <div className="mt-separator">
                      <h2 className="text-uppercase sep-line-one font-weight-900 m-b0">
                        <span className="font-weight-900 text-primary">
                          Locate
                        </span>{" "}
                        Us
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="gmap-outline p-b25">
                  <div style={{ width: "100%" }}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62650.71561273595!2d76.93523199164095!3d11.06336980000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba858526b5c0f87%3A0x144ab3e895bffab9!2sCheran%20Machines%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1719563237997!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      frameBorder="0"
                      style={{ border: 0 }}
                      allowFullScreen
                      title="Google Maps Embed"
                    ></iframe>
                  </div>
                </div>
              </div>

              {/* <div className="gmap-outline">
                <div style={{ height: "400px", width: "100%" }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15662.63080100964!2d76.96417767956139!3d11.064269699689165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8f7dea6e91385%3A0xec3a711c6ef70fd9!2s142%2C%20Bharathi%20St%2C%20Chinnavedampatti%2C%20Coimbatore%2C%20Tamil%20Nadu%20641049!5e0!3m2!1sen!2sin!4v1716804240644!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                  ></iframe>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <MapComponent/> */}
        <ToastContainer />

        <Footer />
      </>
    );
  }
}

export default ContactUs;
