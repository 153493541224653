import React from "react";
import CountUp from "react-countup";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import StarRatings from "react-star-ratings";

var img1 = require("./../../images/video-bg.jpg");

class OurValue extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }

  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t40 p-b30 bg-white">
          <div className="container">
            <div className="section-head">
              <div className="mt-separator-outer separator-center text-wh">
                <div className="mt-separator-value mt-separator" >
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-500 text-purple">Our</span>{" "}
                    Values
                  </h2>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  {/* TITLE START */}
                  <div className="mt-separator-outer separator-left">
                    <h3 style={{ marginTop: "0px" }}>
                      <span
                        style={{ color: "#9E1975" }}
                        className="font-weight-100"
                      >Turning Your Dreams into Reality:
                      </span>{" "}
                      Our Commitment to Excellence
                    </h3>
                    <p>
                      Cheran has been aided by a robust, technically experienced
                      team of engineers and dedicated service staff to provide
                      personalized support and guidance to our customers before,
                      during, and after sales.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>Machine Design and Development</li>
                      <li>Preventive Maintenance Services</li>
                      <li>Operational Efficiency Management</li>
                    </ul>

                    {/* <p>Cheran machines India private limited is always committed to excellence and advancement. We stand one step ahead of our customers expectations. Being a consistent leader in our field we always focus on Innovation, Advanced Technology, and Creativity.</p> */}
                  </div>
                  {/* TITLE END */}

                  {/* <div className="video-section-full bg-no-repeat bg-cover overlay-wraper" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                                        <div className="overlay-main bg-black opacity-07" />
                                        <div className="video-section-inner">
                                            <div className="video-section-content">
                                                
                                                <div className="video-section-left">
                                                    <button type="button" className="play-now" data-toggle="modal" data-target="#myModal2">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </button>
                                                </div>
                                                <div className="video-section-right">
                                                <NavLink to={"#"} className="font-weight-600 text-uppercase" data-toggle="modal" data-target="#myModal">Video Presentation</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
                <div className="col-md-3 col-sm-6">
                  <div
                    className="mt-count m-b30 text-white mt-icon-box-wraper center  p-a20"
                    style={{ backgroundColor: "#eeeeee" }}
                  >
                    <div className="counter font-30 font-weight-800 m-b15 text-primary">
                      <StarRatings
                        rating={4.3}
                        starRatedColor="#9E1975"
                        numberOfStars={5}
                        name="rating"
                        starDimension="30px"
                        starSpacing="3px"
                      />
                    </div>
                    <h4 className="m-tb0" style={{ color: "black" }}>
                      India Mart
                    </h4>
                  </div>
                  <div
                    className="mt-count m-b30 text-white mt-icon-box-wraper center  p-a20"
                    style={{ backgroundColor: "#eeeeee" }}
                  >
                    <div className="counter font-30 font-weight-800 m-b15 text-primary">
                      <StarRatings
                        rating={4}
                        starRatedColor="#9E1975"
                        numberOfStars={5}
                        name="rating"
                        starDimension="30px"
                        starSpacing="3px"
                      />
                    </div>
                    <h4 className="m-tb0" style={{ color: "black" }}>
                      Trade India
                    </h4>
                  </div>
                  <div
                    className="mt-count m-b30 text-white mt-icon-box-wraper center  p-a20"
                    style={{ backgroundColor: "#eeeeee" }}
                  >
                    <div className="counter font-30 font-weight-800 m-b15 text-primary">
                      <StarRatings
                        rating={4.6}
                        starRatedColor="#9E1975"
                        numberOfStars={5}
                        name="rating"
                        starDimension="30px"
                        starSpacing="3px"
                      />
                    </div>
                    <h4 className="m-tb0" style={{ color: "black" }}>
                      Google Review
                    </h4>
                  </div>
                </div>
                <div className="col-md-5 col-sm-6 mob-none">
                  <div className="p-b0 value-right">
                    {/* <div className="mt-box">
                      <h3 className="m-t0">
                        <span
                          className="font-weight-100"
                          style={{ color: "black" }}
                        >
                          {" "}
                          We have
                        </span>
                        <span className="text-primary"> 25+ years</span>{" "}
                        <span style={{ color: "black" }}>of experience</span>
                      </h3>
                    </div> */}
                    <span className="progressText text-black">
                      <b>Quality</b>
                    </span>
                    <div className="progress mt-probar-1 m-b30 m-t10">
                      <div
                        className="progress-bar bg-primary "
                        role="progressbar"
                        aria-valuenow={85}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span
                          className="popOver"
                          data-toggle="tooltips"
                          data-placement="top"
                          title="85%"
                        />
                      </div>
                    </div>
                    <span className="progressText text-black">
                      <b>Innovation</b>
                    </span>
                    <div className="progress mt-probar-1 m-b30 m-t10">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        aria-valuenow={93}
                        aria-valuemin={10}
                        aria-valuemax={100}
                      >
                        <span
                          className="popOver"
                          data-toggle="tooltips"
                          data-placement="top"
                          title="93%"
                        />
                      </div>
                    </div>
                    <span className="progressText text-black">
                      <b>Customer Satisfaction</b>
                    </span>
                    <div className="progress mt-probar-1 m-b30 m-t10">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        aria-valuenow={98}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span
                          className="popOver"
                          data-toggle="tooltips"
                          data-placement="top"
                          title="98%"
                        />
                      </div>
                    </div>
                    <span className="progressText text-black">
                      <b>Excellence</b>
                    </span>
                    <div className="progress mt-probar-1 m-b30 m-t10">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        aria-valuenow={88}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span
                          className="popOver"
                          data-toggle="tooltips"
                          data-placement="top"
                          title="88%"
                        />
                      </div>
                    </div>
                    <span className="progressText text-black">
                      <b>Team Work</b>
                    </span>
                    <div className="progress mt-probar-1 m-b30 m-t10">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        aria-valuenow={100}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span
                          className="popOver"
                          data-toggle="tooltips"
                          data-placement="top"
                          title="100%"
                        />
                      </div>
                    </div>
                    <span className="progressText text-black">
                      <b>Reliability</b>
                    </span>
                    <div className="progress mt-probar-1 m-b30 m-t10">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        aria-valuenow={90}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span
                          className="popOver"
                          data-toggle="tooltips"
                          data-placement="top"
                          title="90%"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="myModal2" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactPlayer url="https://vimeo.com/34741214" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurValue;
