import React from "react";
import imgofmodal from "../../assets/media/images/Gartex & KnitShow 2024 Banner.png";

const Modal = ({ show, hideModal }) => (
  <div
    className={`modal-banner ${show ? " modal-show" : ""}`}
    tabIndex="-1"
    role="dialog"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content-banner">
        <div className="modal-header" style={{ padding: "0px" }}>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={hideModal}
          >
            <span aria-hidden="true" style={{ color: "black" }}>
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body" style={{ padding: "0px" }}>
          <img src={imgofmodal} alt="modalimage"></img>
        </div>
        {/* <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={hideModal}>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={hideModal}
          >
            Close
          </button>
        </div> */}
      </div>
    </div>
  </div>
);

export default Modal;
