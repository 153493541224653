import React from "react";
import Navigation from "../Common/Navigation";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import $ from "jquery";

import "bootstrap/dist/js/bootstrap.bundle.min";
const bnr = require("./../../images/background/bg-5.png");
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: require("./../../images/Cheranlogo.png"),
      open: false,
      isSearchActive: false,
      isQuoteActive: false,
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSearchToggle = this.handleSearchToggle.bind(this);
    this.handleQuoteToggle = this.handleQuoteToggle.bind(this);
    this.handleContactNavClick = this.handleContactNavClick.bind(this);
  }

  handlemodalopen() {
    $("#with-form").modal("show");
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSearchToggle() {
    this.setState((prevState) => ({
      isSearchActive: !prevState.isSearchActive,
    }));
  }

  handleQuoteToggle() {
    this.setState((prevState) => ({ isQuoteActive: !prevState.isQuoteActive }));
  }

  handleContactNavClick(event) {
    event.preventDefault();
    this.handleQuoteToggle();
  }

  componentDidMount() {
    const handleScroll = () => {
      const offset = window.scrollY;

      const stickyheader = document.querySelector(".sticky-header ");

      if (offset >= 100) {
        stickyheader.classList.add("is-fixed");
        stickyheader.classList.add("color-fill");
      } else {
        stickyheader.classList.remove("is-fixed");
        stickyheader.classList.remove("color-fill");
      }
    };

    window.addEventListener("scroll", handleScroll);

    window.updateTopMostParent = (logopath) => {
      this.setState({ logo: logopath });
    };
  }

  render() {
    const { open } = this.state; // Destructuring state
    const { handleClose } = this; // Destructuring methods
    const { handlemodalopen } = this;
    const isSearchActive = this.state.isSearchActive;
    const isQuoteActive = this.state.isQuoteActive;

    return (
      <>
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                console.log(formJson);
                handleClose();
              },
            }}
          >
            {/* <DialogTitle>Submit</DialogTitle> */}
            <DialogContent>
              {/* <DialogContentText>
        To subscribe to this website, please enter your information below. We will send updates occasionally.
      </DialogContentText> */}
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="name"
                label="Name"
                fullWidth
                variant="standard"
                InputLabelProps={{ style: { fontSize: 18 } }} // Set font size here
              />
              <TextField
                required
                margin="dense"
                id="email"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                InputLabelProps={{ style: { fontSize: 18 } }} // Set font size here
              />
              <TextField
                required
                margin="dense"
                id="phone"
                name="phone"
                label="Phone Number"
                fullWidth
                variant="standard"
                InputLabelProps={{ style: { fontSize: 18 } }} // Set font size here
              />
              <TextField
                required
                margin="dense"
                id="description"
                name="description"
                label="Description"
                fullWidth
                multiline
                rows={4}
                variant="standard"
                InputLabelProps={{ style: { fontSize: 18 } }} // Set font size here
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Submit</Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>

        <header className="site-header header-style-1">
          {/* <div className="button-model col-md-3 col-sm-6">
            <button
              className="m-b30"
              // data-toggle="modal"
              // data-target="#with-form"
              onClick={this.showModal}
            >
              <img src={require("./../../images/gallery/pic4.jpg")} alt="" />
              <div className="text-center p-t20 m-b30">
                <h4> With-form</h4>
              </div>
            </button>
          </div> */}
          <div className="top-bar bg-gray">
            <div className="container container-none">
              <div className="row">
                <div className="mt-topbar-left clearfix">
                  <ul className="list-unstyled e-p-bx pull-right">
                    <a href="mailto:cheran@cheranmachines.com">
                      {" "}
                      <li>
                        <i className="fa fa-envelope" />{" "}
                        cheran@cheranmachines.com
                      </li>
                    </a>
                    <a href="tel:+9843912054">
                      <li>
                        <i className="fa fa-phone" />
                        9843912054
                      </li>
                    </a>
                    <li>
                      <i className="fa fa-clock-o" />
                      Mon-Sat 9.00 am - 6:00 pm
                    </li>
                  </ul>
                </div>
                <div className="mt-topbar-right clearfix">
                  <div className="button-model">
                    {/* <div
                      className="appint-btn site-button"
                      data-toggle="modal"
                      data-target="#with-forms"
                    >
                      Make an Appointment
                    </div> */}
                    {/* <div className="appint-btn" data-toggle="modal" data-target="#with-form" ><NavLink to={"#"} className="site-button" >Make an Appointment</NavLink></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky-header main-bar-wraper">
            <div className="main-bar bg-white">
              <div className="container">
                <div className="logo-header">
                  <div className="logo-header-inner logo-header-one">
                    <NavLink to={"/"}>
                      <img src={this.state.logo} alt="Shapen" />
                    </NavLink>
                  </div>
                </div>
                {/* NAV Toggle Button */}
                <button
                  data-target=".header-nav"
                  data-toggle="collapse"
                  type="button"
                  className="navbar-toggle collapsed"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                {/* ETRA Nav */}
                <div className="extra-nav">
                  <div className="extra-cell">
                    <NavLink to={"#"} onClick={this.handleSearchToggle}>
                      {/* <i className="fa fa-search" /> */}
                    </NavLink>
                  </div>
                  <div className="extra-cell">
                    <NavLink
                      to={"#"}
                      className="contact-slide-show"
                      onClick={this.handleContactNavClick}
                    >
                      <i className="fa fa-user " />
                    </NavLink>
                  </div>
                </div>
                {/* ETRA Nav */}
                {/* Contact Nav */}
                <div
                  className="contact-slide-hide "
                  style={{
                    backgroundImage: "url(" + bnr + ")",
                    right: isQuoteActive ? "0px" : "-500px",
                  }}
                >
                  <div className="contact-nav">
                    <NavLink
                      to={"#"}
                      className="contact_close"
                      onClick={this.handleContactNavClick}
                    >
                      ×
                    </NavLink>
                    <div className="contact-nav-form p-a30">
                      <div className="contact-info   m-b30">
                        <div className="mt-icon-box-wraper center p-b30">
                          <div className="icon-xs m-b20 scale-in-center">
                            <i className="fa fa-phone" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t01 font-weight-500">
                              Phone Number
                            </h5>
                            <a href="tel:9843912054">
                              {" "}
                              <p>+91-98439-12054</p>
                            </a>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper center p-b30">
                          <div className="icon-xs m-b20 scale-in-center">
                            <i className="fa fa-envelope" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t01 font-weight-500">
                              Email Address
                            </h5>
                            <a href="mailto:cheran@cheranmachines.com">
                              <p>cheran@cheranmachines.com</p>
                            </a>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper center p-b30">
                          <div className="icon-xs m-b20 scale-in-center">
                            <i className="fa fa-map-marker" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t01 font-weight-500">
                              Address Info
                            </h5>
                            {/* <p>CHERAN MACHINES INDIA PRIVATE LIMITED
                                                         No.142/3, BHARATHI STREET,UDAYAMPALAYAM MAIN ROAD,
                                                         CHINNAVEDAMPATTICOIMBATORE, Coimbatore, Tamil Nadu,
                                                       PIN - 641049</p> */}
                            <p>Cheran Machines India Private Limited</p>
                            <p>
                              No.142/3, Bharathi Street, Udayampalayam Main
                              Road,
                            </p>
                            <p>Chinnavedampatti, Coimbatore, Tamil Nadu</p>
                            <p>Pin - 641049</p>
                          </div>
                        </div>
                      </div>
                      <div className="full-social-bg">
                        <ul
                          style={{
                            display: "flex",
                            fontSize: "30px",
                            justifyContent: "center",
                            gap: "30px",
                          }}
                        >
                          <li>
                            <a
                              className="fa fa-facebook facebook"
                              href="https://www.facebook.com/cheranmachinesindia/"
                              target="_blank"
                            ></a>
                          </li>

                          {/* <li>
                            <NavLink to={"#"} className="facebook">
                              <i className="fa fa-facebook" />
                            </NavLink>
                          </li> */}
                          {/* <li><NavLink to={"#"} className="google"><i className="fa fa-google" /></NavLink></li> */}
                          <li>
                            <a
                              className="fa fa-instagram instagram"
                              href="https://www.instagram.com/cheranmachines?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                              target="_blank"
                            ></a>
                          </li>

                          {/* <li>
                            <NavLink to={"#"} className="instagram">
                              <i className="fa fa-instagram" />
                            </NavLink>
                          </li> */}
                          {/* <li><NavLink to={"#"} className="tumblr"><i className="fa fa-tumblr" /></NavLink></li> */}
                          {/* <li><NavLink to={"#"} className="twitter"><i className="fa fa-twitter" /></NavLink></li> */}
                          <li>
                            <a
                              className="fa fa-linkedin linkedin"
                              href="https://www.linkedin.com/company/cheran-machines-india-pvt-ltd/"
                              target="_blank"
                            ></a>
                          </li>

                          {/* <li>
                            <NavLink
                              to={"your-linkedin-profile-url"}
                              className="linkedin"
                            >
                              <i className="fa fa-linkedin" />
                            </NavLink>
                          </li> */}
                          <li>
                            <a
                              className="fa fa-youtube youtube"
                              href="https://youtube.com/@cheranmachinesindiaprivate6463?si=8-DfCsPAWWPswVmG"
                              target="_blank"
                            ></a>
                          </li>

                          {/* <li>
                            <NavLink to={"#"} className="youtube">
                              <i className="fa fa-youtube" />
                              <a
                                href="https://youtube.com/@cheranmachinesindiaprivate6463?si=8-DfCsPAWWPswVmG
"
                              ></a>
                            </NavLink>
                          </li> */}
                        </ul>
                      </div>
                      <div className="text-center">
                        <p className="" style={{ fontSize: "15px" }}>
                          © 2024 Cheran Machines India Pvt Ltd
                        </p>
                        <p className="" style={{ fontSize: "15px" }}>
                          {" "}
                          All Rights Reserved.
                        </p>
                        <p className="" style={{ fontSize: "15px" }}>
                          Powered by Zerame Infotech
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* SITE Search */}
                <div id="search" className={isSearchActive ? "open" : null}>
                  <span className="close" onClick={this.handleSearchToggle} />
                  <form
                    role="search"
                    id="searchform"
                    action="/search"
                    method="get"
                    className="radius-xl"
                  >
                    <div className="input-group">
                      <input
                        defaultValue=""
                        name="q"
                        type="search"
                        placeholder="Type to search"
                      />
                      <span className="input-group-btn">
                        <button type="button" className="search-btn">
                          <i className="fa fa-search arrow-animation" />
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
                {/* MAIN Vav */}
                <Navigation />
              </div>
            </div>
          </div>
        </header>

        {/* MODAL */}

        <div id="with-forms" className="modal " role="dialog">
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-header bg-secondry">
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
                <h4 className="modal-title text-white">Get In Touch</h4>
              </div>
              <div className="modal-body">
                <form
                  id="demo-form"
                  className="form-horizontal mb-lg"
                  noValidate
                >
                  <div className="form-group mt-lg">
                    <label className="col-sm-3 control-label">Name</label>
                    <div className="col-sm-9">
                      <input
                        name="name"
                        className="form-control"
                        placeholder="Type your name..."
                        required
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Email</label>
                    <div className="col-sm-9">
                      <input
                        name="email"
                        className="form-control"
                        placeholder="Type your email..."
                        required
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Phone No</label>
                    <div className="col-sm-9">
                      <input
                        name="url"
                        className="form-control"
                        placeholder="Type an Phone Number..."
                        type="url"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Comment</label>
                    <div className="col-sm-9">
                      <textarea
                        rows={5}
                        className="form-control"
                        placeholder="Type your comment..."
                        required
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="site-button btn-effect text-uppercase button-sm letter-spacing-2 m-r15"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="site-button btn-effect text-uppercase button-sm letter-spacing-2"
                >
                  Save Changes{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL */}
        <div
          id="with-form"
          className="modal fade in"
          role="dialog"
          style={{ zIndex: "9999" }}
        >
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-header bg-secondry">
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
                <h4 className="modal-title text-white">Modal Header</h4>
              </div>
              <div className="modal-body">
                {/* <img src={} alt="loadingingimage"></img> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="site-button btn-effect text-uppercase button-sm letter-spacing-2 m-r15"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="site-button btn-effect text-uppercase button-sm letter-spacing-2"
                >
                  Save Changes{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
