import React from "react";
import { Helmet } from "react-helmet";
// import ds from "./../../../public/favicon1.png"
class AboutMeta extends React.Component {
  render() {
    return (
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Cheran Machines</title>
          <link
            rel="canonical"
            href="https://cheranmachines.zerame.com/about"
          />
          <meta
            name="description"
            content="Learn more about Cheran Machines, a leading manufacturer and exporter of high-performance textile and garment printing machinery."
          />
          <meta property="og:title" content="og title About Cheran Machines" />
          <meta
            property="og:description"
            content="Learn more about Cheran Machines, snufacturer and exporter of high-performance textile and garment printing machinery."
          />
          <meta property="og:image" content="./../../../public/favicon1.png" />
        </Helmet>
        ...
      </div>
    );
  }
}

export default AboutMeta;
