import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import img5 from "./../../images/gallery/portrait/pic5.jpg";
// import img2 from "./../../images/our-team5/Frame34.jpg";
import img1 from "./../../images/gallery/25years/1.png";
import img2 from "./../../images/gallery/25years/2.png";
import img3 from "./../../images/gallery/25years/3.png";
import img4 from "./../../images/gallery/25years/4.png";



var bnr1 = require("./../../images/background/line.png");

class About4 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }

  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: true,
    };

    const imgStyle = {
      width: "100%",
      height: "400px",
      objectFit: "cover",
    };

    return (
      <>
        <div className="section-full p-t40 m-b20 bg-white inner-page-padding ">
          <div className="container">
            <div className="section-content ">
              <div className="row">
                <div className="col-lg-7 col-md-7 mob-abt-top">
                  <OwlCarousel {...options}>
                  <div className="item">
                      
                      <img src={img5} alt="Image 5" style={imgStyle} />
                    </div>
                    <div className="item">
                      
                      <img src={img1} alt="Image 1" style={imgStyle} />
                    </div>
                    <div className="item">
                      <img src={img2} alt="Image 2" style={imgStyle} />
                    </div>
                    <div className="item">
                      <img src={img3} alt="Image 3" style={imgStyle} />
                    </div>
                    <div className="item">
                      <img src={img4} alt="Image 4" style={imgStyle} />
                    </div>
                  </OwlCarousel>
                </div>
                <div className="col-lg-5 col-md-5">
                  <div
                    className="m-about-containt text-black"
                    style={{ paddingTop: "10px" }}
                  >
                    <div
                      className="m-about-years bg-moving"
                      // style={{ backgroundImage: "url(" + bnr1 + ")" }}
                    >
                      <span className="text-primary large-title">30+</span>
                      <span className="large-title-info">
                        Years of Experience
                      </span>
                    </div>
                    <h3 className="font-weight-600">
                      Cheran Machines India Pvt Ltd.
                    </h3>
                    <p>
                      At Cheran, we stand at the forefront of innovation,
                      dedicated to delivering cutting-edge solutions to the
                      ever-dynamic needs of the industry. With a rich legacy of
                      25+ years, our commitment to excellence remains
                      unwavering. Together, let's shape the future of
                      manufacturing!
                    </p>
                    {/* <div className="author-info p-t30">
                      <div className="author-name">
                        <h4 className="m-t01" style={{ marginBottom: "0" }}>
                          Mohankumar Rathinaswamy
                        </h4>
                        <p>Founder &amp; Managing Director</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About4;
