import React from "react";

const ScrolToTop = () => {
  return (
    <>
      {/* <div class="scrolltop-wrap">
    <a href="#" role="button" aria-label="Scroll to top">
        <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
            <path id="scrolltop-bg" d="M0 0h48v48h-48z"></path>
            <path id="scrolltop-arrow" d="M14.83 30.83l9.17-9.17 9.17 9.17 2.83-2.83-12-12-12 12z"></path>
        </svg>
    </a>
  </div> */}
      <button className="scroltop">
        <span
          className="fa fa-angle-up relative"
          id="btn-vibrate"
          style={{ fontSize: "30px", fontWeight: "bold" }}
        />
      </button>
    </>
  );
};

export default ScrolToTop;
