import React from "react";
import { NavLink } from "react-router-dom";
import CountUp from "react-countup";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const images = [
  require("./../../images/gallery/whoweare.png"),
  require("./../../images/gallery/worker.png"),
  require("./../../images/gallery/Frame41.png"),
];

var bnr1 = require("./../../images/background/bg-map.png");
var icon1 = require("./../../images/icon/employees.png");
var icon2 = require("./../../images/icon/summary-check.png");
var icon3 = require("./../../images/icon/talent-alt.png");
var icon4 = require("./../../images/icon/diploma.png");

class About2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      margin: 30,
      nav: false,
      dots: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        991: {
          items: 1,
        },
      },
    };
    return (
      <>
        <div
          className="section-full "
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="services-half-section-top ">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head">
                <div className="mt-separator-outer separator-center text-wh">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-500 text-purple">Who</span>{" "}
                      We Are
                    </h2>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              <div className="section-content">
                <div className="row">
                  <div className="col-lg-8 col-md-12 col-sm-12">
                    <OwlCarousel
                      className="owl-carousel about-home about-home-v2 owl-dots-bottom-left"
                      {...options}
                    >
                      {images.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="mt-img-effect zoom-slow">
                            <NavLink to="/about">
                              <img src={item} alt="" />
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div
                      className="about-home-right about-right-v2 bg-primary text-white"
                      style={{ padding: "43px 30px 17px 30px" }}
                    >
                      <h3
                        className="m-t0 "
                        style={{ fontSize: "30px", lineHeight: "30px" }}
                      >
                        <span className="font-weight-100"></span> An ISO 9001
                        Certified Company
                      </h3>
                      <p>
                        <strong>
                          A leading manufacturer of garment and textile printing
                          machineries. With decades of innovation and
                          excellence, Cheran’s efforts are directed towards
                          doing it right the first time & every time to meet the
                          diverse needs of the industry.
                        </strong>
                      </p>
                      {/* <div className="text-left">
                        <NavLink
                          to="/about"
                          className="site-button-secondry btn-effect"
                          style={{ marginTop: "9px" }}
                        >
                          Read More
                        </NavLink>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="services-half-section-bottom p-t80 p-b20  bg-secondry bg-cover bg-center bg-no-repeat"
            style={{ backgroundColor: "rgb(238,238,238)" }}
          >
            <div className="container">
              <div className="section-content">
                <div className="row we-bottom">
                  <div className="col-md-3 col-sm-6 mob-cen plleft-80">
                    <div className="text-purple mt-icon-box-wraper left m-b30">
                      <span className="icon-md p-t20">
                        <img
                          src={icon1}
                          style={{ height: "50px", width: "50px" }}
                        />
                      </span>
                      <div className="icon-content text-purple">
                        <div className="counter font-50 font-weight-800 m-b5">
                          <CountUp end={50} duration={5} />
                          <span>+</span>
                        </div>
                        <span className="font-16">Team</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 mob-cen p-l40">
                    <div className="text-primary mt-icon-box-wraper left m-b30">
                      <span className="icon-md p-t20">
                        <img
                          src={icon2}
                          style={{ height: "50px", width: "50px" }}
                        />
                      </span>
                      <div className="icon-content text-purple">
                        <div className="counter font-50 font-weight-800 m-b5">
                          <CountUp end={40} duration={5} />
                          <span>+</span>
                        </div>
                        <span className="font-16">Projects Done</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 mob-cen">
                    <div className="text-primary mt-icon-box-wraper left m-b30">
                      <span className="icon-md p-t20">
                        <img
                          src={icon3}
                          style={{ height: "50px", width: "50px" }}
                        />
                      </span>
                      <div className="icon-content text-purple">
                        <div className="counter font-50 font-weight-800 m-b5">
                          <CountUp end={1500} duration={5} />
                          <span>+</span>
                        </div>
                        <span className="font-16">Happy Clients</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6 mob-cen">
                    <div className="text-primary mt-icon-box-wraper left m-b0">
                      <span className="icon-md p-t20">
                        <img
                          src={icon4}
                          style={{ height: "50px", width: "50px" }}
                        />
                      </span>
                      <div
                        className="icon-content text-purple"
                        style={{ paddingBottom: "30px" }}
                      >
                        <div className="counter font-50 font-weight-800 m-b5">
                          <CountUp end={30} duration={5} />
                          <span>+</span>
                        </div>
                        <span className="font-16">Experience</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About2;
