import React from "react";
import { NavLink } from "react-router-dom";

class AboutCompany extends React.Component {
  render() {
    return (
      <>
        <div className="section-full section-conne-mob p-t50 bg-white ">
          <div className="container">
            
            <div className="section-content ">
              <div className="m-service-containt text-black">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <div className="service-about-left">
                      <div className="mt-media">
                        <img src={require("./../../images/s-1.png")} alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-7 col-sm-12 "
                    style={{ paddingTop: "20px" }}
                  >
                    <div className="service-about-right m-b30">
                      <h3 className="m-t01 m-b20">
                        Redefining Machinery: Exceptional Quality, Exquisite
                        Design
                      </h3>
                      <p>
                        Cheran stands for its state-of-the-art equipment with
                        superior performance, a strong commitment to quality,
                        and advanced solutions that enhance productivity. At
                        Cheran, we believe in adapting to dynamic and
                        fast-changing markets. Our cutting-edge machines are
                        designed to deliver high-precision, durable prints.
                      </p>
                      <p style={{ marginBottom: "15px" }}>
                        We pride ourselves on our customer-centric approach,
                        offering tailored solutions according to the customer's
                        needs and providing ongoing technical support to ensure
                        your success. With a well-knit, synergic team, Cheran is
                        actively expanding its client base and continuously
                        striving to exceed customer expectations.
                      </p>

                      <div className="call-for-quote-outer">
                        <div className="call-quote text-white">
                          <span>Call For a Quote:</span>
                          <h4 style={{ marginTop: "5px", marginBottom: "0px" }}>
                            <a
                              href="tel:+914222667691"
                              style={{ color: "white" }}
                            >
                              0422-2667691
                            </a>
                          </h4>
                        </div>

                        <div className="call-estimate mob-connect">
                          <NavLink
                            to={"/contactus"}
                            className="site-button-secondry btn-effect"
                          >
                            Connect With us
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL */}

        <div id="with-forms" className="modal " role="dialog">
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-header bg-secondry">
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
                <h4 className="modal-title text-white">Get In Touch</h4>
              </div>
              <div className="modal-body">
                <form
                  id="demo-form"
                  className="form-horizontal mb-lg"
                  noValidate
                >
                  <div className="form-group mt-lg">
                    <label className="col-sm-3 control-label">Name</label>
                    <div className="col-sm-9">
                      <input
                        name="name"
                        className="form-control"
                        placeholder="Type your name..."
                        required
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Email</label>
                    <div className="col-sm-9">
                      <input
                        name="email"
                        className="form-control"
                        placeholder="Type your email..."
                        required
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Phone No</label>
                    <div className="col-sm-9">
                      <input
                        name="url"
                        className="form-control"
                        placeholder="Type an Phone Number..."
                        type="url"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Comment</label>
                    <div className="col-sm-9">
                      <textarea
                        rows={5}
                        className="form-control"
                        placeholder="Type your comment..."
                        required
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="site-button btn-effect text-uppercase button-sm letter-spacing-2 m-r15"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="site-button btn-effect text-uppercase button-sm letter-spacing-2"
                >
                  Save Changes{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutCompany;
