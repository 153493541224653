import React from "react";
import { NavLink } from "react-router-dom";

var bnr1 = require("./../../images/background/bg-6.png");

class Specialization3 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full p-t80 p-b50 mobile-page-padding"
          style={{ backgroundColor: "#eeeeee" }}
        >
          <div className="container">
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="section-head m-b50 text-black text-center">
                <h3 className="m-t0 text-black">Cheran Machine Products.</h3>
                <p>
                  These machines are adept at handling various printing
                  techniques, such as screen printing, digital printing, and
                  heat transfer printing, catering to the dynamic demands of the
                  garment industry.
                </p>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img
                      src={require("../../images/services/Frame3.png")}
                      alt=""
                    />
                    <div className="figcaption">
                      <h4>Feature</h4>
                      <p>Servo drive for better positioning control.</p>
                      <NavLink to="/project-detail">
                        <i className="link-plus bg-primary" />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img
                      src={require("../../images/services/Frame1.png")}
                      alt=""
                    />
                    <div className="figcaption">
                      <h4>Feature</h4>
                      <p>Fast continuous production . </p>
                      <NavLink to="/project-detail">
                        <i className="link-plus bg-primary" />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img
                      src={require("../../images/services/Frame2.png")}
                      alt=""
                    />
                    <div className="figcaption bg-dark">
                      <h4>Feature</h4>
                      <p>ESuitable for Embossing.</p>
                      <NavLink to="/project-detail">
                        <i className="link-plus bg-primary" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Specialization3;
