import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";

const logos = [
  { image: require("./../../images/client-logo/MAS Holdings.png") },
  { image: require("./../../images/client-logo/Brandix.png") },
  { image: require("./../../images/client-logo/Arvind  Ltd.png") },
  { image: require("./../../images/client-logo/Wildcraft.png") },
  { image: require("./../../images/client-logo/NZeel.png") },
  { image: require("./../../images/client-logo/Jay Jay Mills.png") },
  {
    image: require("./../../images/client-logo/Eastman Exports Global Clothing Pvt. Ltd..png"),
  },
  { image: require("./../../images/client-logo/Bewakoof.png") },
  { image: require("./../../images/client-logo/SP Apparels.png") },
  { image: require("./../../images/client-logo/Mochiko.png") },
  { image: require("./../../images/client-logo/Shivalik Prints.png") },
  { image: require("./../../images/client-logo/JGH.png") },
  { image: require("./../../images/client-logo/Body Glove.png") },
  { image: require("./../../images/client-logo/Royal Classic Mills.png") },
  { image: require("./../../images/client-logo/HABEEB TANNING COMPANY.png") },
  { image: require("./../../images/client-logo/PRATIBHA SYNTEX.png") },
  { image: require("./../../images/client-logo/Rupa & Co..png") },
  { image: require("./../../images/client-logo/Jasbin Trading (1).png") },
  { image: require("./../../images/client-logo/Texport Syndicate.png") },
  { image: require("./../../images/client-logo/Pearl Global (1).png") },
  { image: require("./../../images/client-logo/Candor (1).png") },
  { image: require("./../../images/client-logo/thesleep company.png") },
  { image: require("./../../images/client-logo/Harita Seating.png") },
  {
    image: require("./../../images/client-logo/Worldwide Safety Private Limited (1).png"),
  },
  {
    image: require("./../../images/client-logo/Augustan Knitwear Pvt Ltd.png"),
  },
  {
    image: require("./../../images/client-logo/H P Cotton Casuals Pvt Ltd.png"),
  },
  { image: require("./../../images/client-logo/brij.png") },
  { image: require("./../../images/client-logo/Vissco.png") },
  { image: require("./../../images/client-logo/Exodus Futura (1).png") },
  {
    image: require("./../../images/client-logo/Tata International Shoes (1).png"),
  },
  { image: require("./../../images/client-logo/Duke 1.png") },
  {
    image: require("./../../images/client-logo/Kudu Knit Process Pvt. Ltd.png"),
  },
  { image: require("./../../images/client-logo/Vardhman (1).png") },
  { image: require("./../../images/client-logo/Rangoli Furnishings 1.png") },
  { image: require("./../../images/client-logo/Knittwelll (1).png") },
  { image: require("./../../images/client-logo/Lux Industries.png") },
  { image: require("./../../images/client-logo/Ecojutes (1).png") },
  { image: require("./../../images/client-logo/Richie Bags (1).png") },
  { image: require("./../../images/client-logo/auburndesign.png") },
  { image: require("./../../images/client-logo/Radhika Print Tex (1).png") },
  { image: require("./../../images/client-logo/popeesbabycare.png") },
  { image: require("./../../images/client-logo/Hyco.png") },
];

class ClientsLogo extends React.Component {
  state = {
    logo: [],
  };

  componentDidMount() {
    axios
      .get("https://cheranmachines.zerame.com/api/client_logo.php")
      .then((response) => {
        this.setState({ logo: response.data.responseJson });
      })
      .catch((error) => {
        console.error("Error fetching the projects:", error);
      });
  }

  render() {
    const options = {
      loop: true,
      margin: 0,
      autoplay: true,
      nav: true,
      smartSpeed: 2000,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        480: {
          items: 3,
        },
        767: {
          items: 4,
        },
        1000: {
          items: 6,
        },
      },
    };
    return (
      <>
        <div className="section-full p-t20 bg-white ">
          <div className="section-head">
            <div className="section-content">
              {/* TESTIMONIAL 4 START ON BACKGROUND */}
              <div className="section-content">
                {/* <div className="section-content p-t10 p-b30 owl-btn-vertical-center"> */}
                <div className="section-content p-t40 p-b40 owl-btn-vertical-center">
                  <div className="section-head">
                    <div className="mt-separator-outer separator-center">
                      <div className=" mt-separator mt-separator-contact">
                        <h2 className="text-uppercase sep-line-one font-weight-900">
                          Happy{" "}
                          <span className="font-weight-900 text-primary">
                            Clients
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <OwlCarousel
                    className="owl-carousel home-client-carousel-2 custom-owl-nav"
                    {...options}
                  >
                    {logos.map((item, index) => (
                      <div className="item" key={index}>
                        <div className="ow-client-logo">
                          <div className="client-logo client-logo-media">
                            <img src={item.image} alt="" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo;
