import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

const mediaItems = [
  { type: "image", src: require("./../../images/rainbow.png") },
  {
    type: "video",
    src: "https://cheranmachines.zerame.com/api/video/Rainbow-Rover-video.mp4",
    thumbnail: require("./../../images/rainbow.png"),
  },
];

class About extends React.Component {
  state = {
    activeIndex: 0,
    videoPlaying: false,
    preventCarouselChange: false, // State to prevent carousel change on video interaction
  };

  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }

  handleTranslated = (event) => {
    if (!this.state.videoPlaying) {
      const { items, item } = event;
      const currentItem = items.indexOf(item);
      this.setState({ activeIndex: currentItem });
    }
  };

  handleVideoPlay = () => {
    this.setState({ videoPlaying: true, preventCarouselChange: true });
  };

  handleVideoPause = () => {
    this.setState({ videoPlaying: false, preventCarouselChange: false });
  };

  handleCarouselSlide = (event) => {
    if (this.state.preventCarouselChange) {
      event.stopPropagation();
    }
  };

  render() {
    const options = {
      loop: true,
      autoplay: !this.state.videoPlaying,
      autoplayTimeout: 5000, // Default autoplay timeout for images
      margin: 30,
      nav: false,
      dots: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        991: {
          items: 1,
        },
      },
      onTranslated: this.handleTranslated,
    };
    var img1 = require("./../../images/background/bg-01.png");
    return (
      <>
        {/* <div
          className="section-full mobile-page-padding p-t80 p-b30 bg-repeats  bg-moving"
          style={{ backgroundImage: "url(" + img1 + ")" }}
        > */}
        <div
          className="section-full mobile-page-padding p-t40 bg-repeats  bg-moving"
          style={{ backgroundImage: "url(" + img1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-center">
                <div className="mt-separator-new mt-separator">
                  <h2 className="text-uppercase sep-line-one">
                    <span className="font-weight-900 text-primary">
                      Cheran's
                    </span>{" "}
                    Featured Products
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="row">
                <div className="col-md-9 col-sm-12">
                  <OwlCarousel
                    className="owl-carousel about-home owl-btn-vertical-center"
                    {...options}
                    onDragged={this.handleCarouselSlide}
                    onChanged={this.handleCarouselSlide}
                  >
                    {mediaItems.map((item, index) => (
                      <div className="item" key={index}>
                        <div className="mt-img-effect zoom-slow">
                          {item.type === "image" ? (
                            <img src={item.src} alt="" />
                          ) : (
                            <div className="video-section-full-v21">
                              <div
                                className="video-section-full bg-no-repeat bg-cover overlay-wraper "
                                style={{
                                  backgroundImage: `url(${item.thumbnail})`,
                                }}
                              >
                                <div className="overlay-main bg-black opacity-04" />
                                <div className="video-section-inner">
                                  <div className="video-section-content">
                                    <div className="video-section-left">
                                      <button
                                        type="button"
                                        className="play-now"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                        onClick={this.openModal}
                                      >
                                        <i className="icon fa fa-play" />
                                        <span className="ripple" />
                                      </button>
                                    </div>
                                    <div className="video-section-right"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
                <div className="col-md-3 col-sm-12">
                  <div className="about-home-right bg-white p-a30 p-b215">
                    <h3 className="m-t0">
                      <span className="font-weight-400 text-black">
                        Rainbow Rover
                      </span>
                    </h3>
                    <p>
                      <strong>
                        We are dedicated to meeting the diverse needs of our
                        clients by offering an exceptional quality range of
                        Cheran's Rainbow Rover.Each Cheran's Rainbow Rover is
                        crafted with meticulous attention to detail, reflecting
                        our promise of quality and reliability. By continuously
                        striving for innovation and maintaining high standards,
                        we guarantee that our clients receive the best solutions
                        tailored to their unique needs
                      </strong>
                    </p>
                    <div className="text-right">
                      <NavLink
                        to="/rainbowproduct/16/Cheran's%20Automatic%20Rainbow%20Rover"
                        className="site-button-link"
                        data-hover="Read More"
                      >
                        Read More{" "}
                        <i className="fa fa-angle-right arrow-animation" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="myModal"
          role="dialog"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-header bg-secondry">
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>
            <div className="modal-content">
              <ReactPlayer
                url="https://cheranmachines.zerame.com/api/video/Rainbow-Rover-video.mp4"
                controls
                width="100%"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About;
