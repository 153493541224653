import { React, useState, useEffect } from "react";
import ReactDOM from "react-dom";

import Components from "./components/Components";
import Floatbutton from "./components/floatbutton";
import ScrolToTop from "./components/Elements/ScrolToTop";
import Loader from "./components/Elements/Loader";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from "./components/Common/Modal";
import "./components/Common/Modal.css";

const App = () => {
  const [show, setShow] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     showModal();
  //   }, 2000);
  // }, []);

  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };
  return (
    <div className="App">
      <Components />
      <ScrolToTop />
      <Loader />
      <Floatbutton />
      <Modal show={show} hideModal={hideModal}>
        Modal content
      </Modal>
    </div>
  );
};

export default App;
