import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";

class SimilarProjects extends React.Component {
  state = {
    projects: [],
    selectedProduct: null,
    projectsVisible: false, // Track if projects are visible in UI
  };

  componentDidMount() {
    axios
      .get("https://cheranmachines.zerame.com/api/product_list.php")
      .then((response) => {
        this.setState({ projects: response.data.responseJson }, () => {
          this.setState({ projectsVisible: true });
        });
      })
      .catch((error) => {
        console.error("Error fetching the projects:", error);
      });

    this.loadScript("./assets/js/masonary.js");
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.projectsVisible && this.state.projectsVisible) {
      console.log("Projects are now visible in UI:", this.state.projects);
    }
  }

  loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
      document.body.removeChild(script);
    });
  };

  render() {
    const options = {
      loop: true,
      autoplay: true,
      center: false,
      items: 3,
      smartSpeed: 2000,
      margin: 40,
      nav: true,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 2,
        },
        991: {
          items: 3,
        },
        1300: {
          items: 4,
        },
      },
    };

    return (
      <div className="section-full bg-repeat p-b40 square_shape2 bg-moving">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="mt-separator-outer separator-center">
              <div className="mt-separator m-b40">
                <h2 className="text-dark text-uppercase sep-line-one ">
                  <span className="font-weight-900 text-primary">Similar</span>{" "}
                  Products
                </h2>
              </div>
            </div>
          </div>
          {/* TITLE END */}
        </div>
        <div className="section-content container-fluid">
          <div className="similar-carousel-outer">
            <OwlCarousel
              className="owl-carousel similar-projects owl-btn-vertical-center"
              {...options}
            >
              {this.state.projects.map((item, index) => (
                <div key={index} className="item mt-box masonry-item">
                  <NavLink
                    to={
                      item.product_title_id === "16"
                        ? `/rainbowproduct/${item.product_title_id}/${item.product_title_name}`
                        : item.video_status === "1"
                        ? `/product-detail/${item.product_title_id}/${item.product_title_name}`
                        : `/product-details2/${item.product_title_id}/${item.product_title_name}`
                    }
                    className=""
                  >
                    <div className="image-effect-two hover-shadow">
                      {" "}
                      <img
                        src={item.product_img}
                        alt={item.product_title_name}
                      />
                      <div className="figcaption">
                        <h4>{item.product_title_name}</h4>
                        <p
                          className="font-weight-600 m-b0"
                          style={{ color: "black" }}
                        >
                          Features :
                        </p>
                        <ul
                          className="list-simple p-l15"
                          style={{ color: "black" }}
                        >
                          {item.features.map((feature, i) => (
                            <li key={i}>{feature.feature}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    );
  }
}

export default SimilarProjects;
