import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const testimonials = [
  {
    image: require("./../../images/Avatar.png"),
    reviewername: "Arjun Patel",
    position: "Textile Manufacturer",
    review:
      "Cheran Machines has transformed our production process with their state-of-the-art textile printing machinery. Their customer support is outstanding, and the quality of their machines is unparalleled.",
  },
  {
    image: require("./../../images/Avatar.png"),
    reviewername: "Samantha Lee",
    position: "Garment Producer",
    review:
      "The garment printing machines from Cheran are incredibly versatile and efficient. Our production speed has increased significantly, and the print quality is exceptional. Highly recommend!",
  },
  {
    image: require("./../../images/Avatar.png"),
    reviewername: "Ravi Kumar",
    position: "Fabric Designer",
    review:
      "Cheran's custom printing solutions have allowed us to create unique designs with ease. Their technical support team is always ready to help and ensures our machines run smoothly.",
  },
  {
    image: require("./../../images/Avatar.png"),
    reviewername: "Laura Chen",
    position: "Apparel Brand Owner",
    review:
      "We have been using Cheran Machines for our garment printing needs, and the results are consistently impressive. Their machines are reliable, and their service team is very responsive.",
  },
  {
    image: require("./../../images/Avatar.png"),
    reviewername: "Michael Johnson",
    position: "Printing Specialist",
    review:
      "Cheran Machines offers top-notch textile printing machinery that delivers excellent performance. Their commitment to quality and customer satisfaction is evident in every interaction.",
  },
];

var bnr1 = require("./../../images/background/bg6.jpg");

class Testimonials extends React.Component {
  render() {
    const options = {
      autoplayTimeout: 8000,
      loop: true,
      autoplay: true,
      margin: 30,
      nav: false,
      smartSpeed: 2000,
      dots: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        991: {
          items: 2,
        },
      },
    };

    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 square_shape2 bg-cover"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="container">
            <div className="section-content">
              {/* TITLE START */}
              <div className="section-head">
                <div className="mt-separator-outer separator-center">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">
                        Client
                      </span>{" "}
                      Testimonials
                    </h2>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              {/* TESTIMONIAL START */}
              <OwlCarousel
                className="owl-carousel testimonial-home"
                {...options}
              >
                {testimonials.map((item, index) => (
                  <div key={index} className="item">
                    <div className="testimonial-2 m-a30 hover-animation-1">
                      <div className=" block-shadow bg-white p-a30">
                        <div className="testimonial-detail clearfix">
                          <div className="testimonial-pic radius shadow scale-in-center">
                            <img
                              src={item.image}
                              width={100}
                              height={100}
                              alt=""
                            />
                          </div>
                          <h4 className="testimonial-name m-b5">
                            {item.reviewername} 
                          </h4>
                          <span className="testimonial-position">
                            {item.position}
                          </span>
                        </div>
                        <div className="testimonial-text">
                          <span className="fa fa-quote-right" />
                          <p> {item.review}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
          {/* <div
            className="hilite-title text-left p-l50 text-uppercase text-pop-up-top"
            style={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <strong>Clients</strong>
          </div> */}
        </div>
      </>
    );
  }
}

export default Testimonials;
