import React from "react";
import { NavLink } from "react-router-dom";

const services = [
  {
    count: 1,
    title: "State-of-the -Art Equipment",
    icon: require("./../../images/icon/mechanic.png"),
    description:
      "Cheran stands for its state-of-the-art equipment with superior performance. Our cutting-edge machines are designed to deliver high-precision, durable prints, ensuring that you receive the best quality every time.",
  },
  {
    count: 2,
    title: "Comprehensive Product Range",
    icon: require("./../../images/icon/productrange.png"),
    description:
      "From automatic textile printers to traditional screen printing machines, we offer a comprehensive range of products catering to diverse business operations and needs. Our equipment ensures precision, durability.",
  },
  {
    count: 3,
    title: "Unified Teamwork Goals",
    icon: require("./../../images/icon/teamwork.png"),
    description:
      "Our team works collaboratively to achieve common goals, enabling us to provide exceptional customer service. We consistently meet and exceed our clients expectations.",
  },
  {
    count: 4,
    title: "Diverse Industry Expertise",
    icon: require("./../../images/icon/key-to-success.png"),
    description:
      "Cheran specializes in advanced equipment meticulously designed to deliver durable, eco-friendly packaging and fashion accessories. Our products are engineered to meet the distinctive requirements. ",
  },
];

var img1 = require("./../../images/background/ptn-1.png");

class Specialization4 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full p-tb40 bg-white m-b40 bg-repeat square_shape2 inner-page-padding"
          style={{ backgroundImage: "url(" + img1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-center">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one mob-abt-btm">
                    <span className="font-weight-900 text-primary">Why</span>{" "}
                    Cheran ?
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                {services.map((item, index) => (
                  <div className="col-md-3 col-sm-6" key={index}>
                    <div className="mt-icon-box-wraper m-b30">
                      <div className="relative icon-count-2 bg-gray p-a30 p-tb50 height-min">
                        <span className="icon-count-number">{item.count}</span>
                        <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                          <span className="icon-cell">
                            <img src={item.icon} alt="" />
                          </span>
                        </div>
                        <div className="icon-content">
                          <h4
                            className="mt-tilte m-b25"
                            style={{ textAlign: "left" }}
                          >
                            {item.title}
                          </h4>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Specialization4;
