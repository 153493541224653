import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Home from "../components/Pages/Home"; // Import the class component

const HomeWithNavigate = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/home");
  }, [navigate]);

  return <Home {...props} />;
};

export default HomeWithNavigate;
