import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';


// var img1 = require('./../../images/video-bg2.jpg');
var img1 = require("./../../images/gallery/portrait/pic5.jpg")



class OurValue2 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <div className="video-section-full-v2">
                                        <div className="video-section-full bg-no-repeat bg-cover overlay-wraper m-b30" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                                            <div className="overlay-main bg-black opacity-04" />
                                                <div className="video-section-inner">
                                                    <div className="video-section-content">
                                                        <div className="video-section-left">
                                                        <button type="button" className="play-now" data-toggle="modal" data-target="#myModal">Open Modal
                                                                <i className="icon fa fa-play" />
                                                                <span className="ripple" />
                                                                </button>
                                                        </div>
                                                        <div className="video-section-right">
                                                            <NavLink to={"#"} className="font-weight-600 text-uppercase" onClick={this.openModal}>Video Presentation</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                  <div className="m-about-containt text-black p-t80">
                    <div
                      className="m-about-years bg-moving"
                      // style={{ backgroundImage: "url(" + bnr1 + ")" }}
                    >
                      <span className="text-primary large-title">25+</span>
                      <span className="large-title-info">
                        Years of Experience
                      </span>
                    </div>
                    <h3 className="font-weight-600">
                      Cheran Machines India Pvt Ltd.
                    </h3>
                    <p>
                      {" "}
                      At Cheran, we stand at the forefront of innovation,
                      dedicated to delivering cutting-edge solutions to the
                      ever-dynamic industry needs With a rich legacy of 30+
                      years, our commitment to excellence remains
                      unwavering.Together, let's shape the future of
                      manufacturing!
                    </p>
                    <div className="author-info p-t30">
                      <div className="author-name">
                        <h4 className="m-t01" style={{ marginBottom: "0" }}>
                          Mohankumar Rathinaswamy
                        </h4>
                        <p>Founder &amp; Managing Director</p>
                      </div>
                    </div>
                  </div>
                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="myModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://vimeo.com/34741214' />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default OurValue2;