import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Table from "../Common/Table";
import SimilarProjects from "./../Elements/SimilarProjects";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const withRouter = (Component) => {
  return (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  };
};

class ProjectDetail extends React.Component {
  state = {
    productDetails: {},
    data2: {},
    features: [],
    firstWord: "",
    restTitle: "",
    images: [],
    isModalOpen: false,
    isPlaying: false,
    iconname: [],
  };

  componentDidMount() {
    const { id } = this.props.params;
    if (id) {
      console.log("Project ID:", id);

      axios
        .post("https://cheranmachines.zerame.com/api/product_detail.php", {
          id,
        })
        .then((response) => {
          const productDetails = response.data.responseJson[0];
          const table_head = productDetails.table_head || [];
          const table_body = productDetails.table_body || [];
          const features = productDetails.features.map((f) => f.feature) || [];
          const images = productDetails.icons || [];
          const [firstWord, ...rest] =
            productDetails.product_title_name.split(" ");
          const restTitle = rest.join(" ");
          const data2 = { table_head, table_body };
          this.setState({
            productDetails,
            data2,
            features,
            firstWord,
            restTitle,
            images,
          });
          console.log("data table", data2);
        })
        .catch((error) => {
          console.error("Error fetching product details:", error);
        });
    } else {
      console.error("No project ID found in URL params.");
    }
  }

  shareOnWhatsApp = (title, message, link) => {
    const combinedMessage = `${title}: ${message}\n${link}`;
    const encodedMessage = encodeURIComponent(combinedMessage);

    window.open(`https://wa.me/?text=${encodedMessage}`);
  };

  openModal = () => {
    this.setState({ isModalOpen: true, isPlaying: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, isPlaying: false });
  };
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: true,
    };
    return (
      <>
        <Helmet>
          <title>{this.state.productDetails.product_title_name}</title>
          <meta
            property="og:title"
            content={this.state.productDetails.product_title_name}
          />
          <meta
            property="og:description"
            content={this.state.productDetails.product_description}
          />
          <meta
            property="og:image"
            content={this.state.productDetails.product_img}
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
        </Helmet>
        <Header />
        <div className="page-content">
          <div
            className="text-uppercase"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "xxx-large",
              lineHeight: "normal",
            }}
          >
            <h3 className="font-weight-900 media-title">
              <span className="font-weight-900 " style={{ color: "#9e1975" }}>
                {this.state.firstWord}{" "}
              </span>{" "}
              {this.state.restTitle}
            </h3>
            <a
              className="mfp-link1"
              type="button"
              data-toggle="modal"
              data-target="#Default-Modal"
              style={{
                marginLeft: "10px", // Adjust the margin as needed
                cursor: "pointer",
                fontSize: "2rem",
                marginTop: "8px",
                paddingRight: "20px",
              }}
            >
              <i className="fa fa-share-alt" />
            </a>
          </div>

          <div className="section-full p-t20 bg-white mobile-page-padding">
            <div className="container">
              <div className="section-content">
                <div className="row">
                  <div className="col-md-7 col-sm-6">
                    <div className="video-section-full-v2">
                      <div
                        className="video-section-full bg-no-repeat bg-cover overlay-wraper m-b30"
                        style={{
                          backgroundImage:
                            "url(" +
                            this.state.productDetails.product_thumbnail +
                            ")",
                        }}
                      >
                        <div className="overlay-main bg-black opacity-04" />
                        <div className="video-section-inner">
                          <div className="video-section-content">
                            <div className="video-section-left">
                              <button
                                type="button"
                                className="play-now"
                                data-toggle="modal"
                                data-target="#myModal"
                                onClick={this.openModal}
                              >
                                <i className="icon fa fa-play" />
                                <span className="ripple" />
                              </button>
                            </div>
                            <div className="video-section-right"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-sm-6">
                    <div className="video-part-2">
                      <h3
                        className="font-weight-900 m-b20 text-uppercase"
                        style={{ color: "#9E1975", fontSize: "30px" }}
                      >
                        Features :
                      </h3>
                      <ul
                        className="list-arrow "
                        style={{ paddingLeft: "25px" }}
                      >
                        {this.state.features.map((feature, index) => (
                          <li key={index}>{feature}</li>
                        ))}
                      </ul>
                      <ul className="icon-text-row">
                        {this.state.images.map((image, index) => (
                          <li className="icon-text-item" key={index}>
                            <div className="mt-icon-box-wraper center m-b30">
                              <div className="scale-in-center bg-moving">
                                <span className="icon-cell text-secondry">
                                  <img
                                    src={image.icon}
                                    style={{
                                      width: "75px",
                                      height: "75px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </span>
                                <p
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    lineHeight: "normal",
                                    fontSize: "10px",
                                    paddingTop: "10px",
                                  }}
                                >
                                  {image.feature}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-full  bg-white inner-page-padding">
            <div className="container">
              <div className="section-content">
                <div className="row">
                  <div className="col-lg-7 p-t80 col-md-7 ptt-30 mb-change">
                    <div className="section-head ">
                      <div className="mt-separator-outer separator-left mb-tit-tech">
                        <div className="mt-separator">
                          <h2 className="text-black text-uppercase sep-line-one ">
                            <span className="font-weight-900 text-primary">
                              Technical
                            </span>{" "}
                            Specification
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Table data={this.state.data2} />
                    </div>
                  </div>
                  <div className="col-md-5 col-sm-12">
                    <div className="service-about-left">
                      <div
                        className="mt-media"
                        style={{ marginBottom: "25px" }}
                      >
                        <img
                          src={this.state.productDetails.product_img}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SimilarProjects />

        <div
          className="modal fade"
          id="myModal"
          role="dialog"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.closeModal}
                  style={{ color: "black" }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <ReactPlayer
                  url={this.state.productDetails.product_video}
                  controls
                  playing={this.state.isPlaying}
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>

        <div id="Default-Modal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            {/* MODAL CONTENT*/}
            <div className="modal-content">
              <div
                className="modal-header bg-secondry"
                style={{ backgroundColor: "#9e1975" }}
              >
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
                <h4 className="modal-title text-white">
                  Share {this.state.productDetails.product_title_name}
                </h4>
              </div>
              <div className="modal-body">
                <FacebookShareButton
                  url={window.location.href}
                  quote={this.state.productDetails.product_title_name}
                  className="social-media-share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>{" "}
                <TwitterShareButton
                  url={window.location.href}
                  title={this.state.productDetails.product_title_name}
                  className="social-media-share-button"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>{" "}
                <WhatsappShareButton
                  url={window.location.href}
                  title={this.state.productDetails.product_title_name}
                  separator=":: "
                  onClick={() =>
                    this.shareOnWhatsApp(
                      this.state.productDetails.product_title_name,
                      "Check out this product at Cheran Machines:",
                      window.location.origin +
                        (this.state.productDetails.product_title_id === "16"
                          ? `/rainbowproduct/${
                              this.state.productDetails.product_title_id
                            }/${encodeURIComponent(
                              this.state.productDetails.product_title_name
                            )}`
                          : this.state.productDetails.video_status === "1"
                          ? `/product-detail/${
                              this.state.productDetails.product_title_id
                            }/${encodeURIComponent(
                              this.state.productDetails.product_title_name
                            )}`
                          : `/product-details2/${
                              this.state.productDetails.product_title_id
                            }/${encodeURIComponent(
                              this.state.productDetails.product_title_name
                            )}`)
                    )
                  }
                  className="social-media-share-button"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default withRouter(ProjectDetail);
