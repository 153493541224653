import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var bnr1 = require("./../../images/background/bg-01.png");
var bnr2 = require("./../../images/background/card1.png");
var bnr3 = require("./../../images/mission.png");

class OurMission3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      phone: "",
      message: "",
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    if (name === "username") {
      // Allow only alphabetic characters and spaces
      const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
      this.setState({ [name]: filteredValue });
    } else if (name === "phone") {
      // Allow only digits
      const filteredValue = value.replace(/[^0-9]/g, "");
      this.setState({ [name]: filteredValue });
    } else {
      this.setState({ [name]: value });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { username, email, phone, message } = this.state;
    const data = { username, email, phone, message };
    this.setState({ loading: true });
    try {
      const response = await fetch(
        "https://cheranmachines.zerame.com/api/form.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("Form submission successful:", responseData);
        toast.success("Form submission successful!");
        this.setState({ loading: false });
        this.setState({
          username: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        console.error("Form submission failed:", response.statusText);
        toast.error("Form submission failed!");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form!");
    }
  }

  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t40 p-b30 m-b40  bg-repeats  bg-moving"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="section-content">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head">
                <div className="mt-separator-outer separator-center">
                  <div className="mt-separator mt-separator-mission">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-900 text-primary">Our</span>{" "}
                      Mission
                    </h2>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              <div className="row">
                <div
                  className="col-md-4 col-sm-12 "
                  style={{ paddingBottom: "20px" }}
                >
                  <div
                    className="mission-left bg-white  p-a30 bg-no-repeat  bg-bottom-left max-height"
                    style={{
                      backgroundImage: "url(" + bnr2 + ")",
                    }}
                  >
                    <h3
                      className=""
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    >
                      <span className="font-weight-100 ">
                        {" "}
                        Driving Innovation
                      </span>
                      <br /> Across Industries
                    </h3>
                    <p>
                      We continually invest in research and development to stay
                      ahead of industry trends and incorporate the latest
                      technologies into our products.{" "}
                    </p>
                    <ul className="list-angle-right anchor-line  anchor-line1">
                      <li>Rainwear Industry</li>
                      <li>Bag Industry</li>
                      <li>Automobile Industry</li>
                    </ul>
                    <div className="text-right"></div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div
                    className="mission-mid bg-no-repeat bg-cover m-b30"
                    style={{
                      backgroundImage: "url(" + bnr3 + ")",
                    }}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <div
                    className="contact-home1-left   m-b0"
                    style={{
                      backgroundColor: "rgb(238, 238, 238)",
                      padding: "23px",
                    }}
                  >
                    <h3 className=" m-t0" style={{ color: "black" }}>
                      <span className="font-weight-100">Get In</span> Touch
                    </h3>
                    <form
                      className="cons-contact-form2 form-transparent"
                      method="post"
                      action=""
                      onSubmit={this.handleSubmit}
                    >
                      <div className="input input-animate">
                        <label htmlFor="name" style={{ color: "black" }}>
                          Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          name="username"
                          id="name"
                          minLength="3"
                          maxLength="20"
                          pattern="[a-zA-Z\s]*"
                          title="Only alphabetic letters and spaces are allowed"
                          value={this.state.username}
                          onChange={this.handleInputChange}
                          required
                        />
                        <span className="spin" />
                      </div>
                      <div className="input input-animate">
                        <label htmlFor="email" style={{ color: "black" }}>
                          Email<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={this.state.email}
                          onChange={this.handleInputChange}
                          required
                        />
                        <span className="spin" />
                      </div>
                      <div className="input input-animate">
                        <label htmlFor="Phone" style={{ color: "black" }}>
                          Phone<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          id="Phone"
                          maxLength="10"
                          value={this.state.phone}
                          onChange={this.handleInputChange}
                          pattern="\d*"
                          title="Only digits are allowed"
                          required
                          aria-label="Phone"
                        />
                        <span className="spin" />
                      </div>
                      <div className="input input-animate">
                        <label
                          htmlFor="message"
                          style={{ color: "black", marginTop: "-12px" }}
                        >
                          Message<span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          name="message"
                          id="message"
                          minLength="5"
                          maxLength="250"
                          value={this.state.message}
                          onChange={this.handleInputChange}
                          required
                          style={{ position: "absolute", top: "3px" }}
                        />
                        <span className="spin" style={{ color: "black" }} />
                      </div>
                      <div className="text-center p-t10">
                        <button
                          type="submit"
                          className="site-button btn-effect "
                          disabled={this.state.loading}
                        >
                          {this.state.loading ? (
                            <>Submiting</>
                          ) : (
                            <>Submit Now</>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
}

export default OurMission3;
