import React from "react";
import { NavLink } from "react-router-dom";

var bnrimg = require("./../../images/background/ownerbg-01.png");

const teamMembers = [
  {
    image: require("./../../images/our-team5/md.png"),
    membername: "Mohankumar Rathinaswamy",
    position: "CEO & Managing Director",
  },
  {
    image: require("./../../images/our-team5/Sam.png"),
    membername: "Shamyukthaa Mohan Kumar",
    position: "Director",
  },
];

var bnr1 = require("./../../images/background/bg5.jpg");

class Team extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t40 bg-repeats  bg-moving p-t50 p-b30"
          data-stellar-background-ratio="0.6"
          style={{ backgroundImage: "url(" + bnrimg + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head text-center text-white ">
              <div className="mt-separator-outer separator-center">
                <div className="mt-separator">
                  <h2
                    className=" text-uppercase sep-line-one font-weight-900"
                    style={{ color: "black", marginBottom: "0px" }}
                  >
                    <span className="font-weight-900 text-primary">Our </span>{" "}
                    Team
                  </h2>
                </div>
              </div>
              <h2 style={{ color: "black" }}>
                Discover trust team and our experts
              </h2>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row user-align" style={{paddingTop:"10px"}}>
                {teamMembers.map((item, index) => (
                  <div
                    key={index}
                    className="col-md-3 col-sm-6 col-xs-6 col-xs-100pc m-b30   "
                  >
                    <div className="our-team-2 ">
                      <div className="profile-image scale-in-center">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="figcaption text-black">
                        <h4 style={{ fontWeight: "600" }}>{item.membername}</h4>
                        <h5>{item.position}</h5>
                        {/* <div className="icons">
                                                    <NavLink to={"#"} ><i className="fa fa-facebook" /></NavLink>
                                                    <NavLink to={"#"} > <i className="fa fa-twitter" /></NavLink>
                                                    <NavLink to={"#"} > <i className="fa fa-instagram" /></NavLink>
                                                    <NavLink to={"#"} ><i className="fa fa-rss" /></NavLink>
                                                    <NavLink to={"#"} > <i className="fa fa-youtube" /></NavLink>
                                                    <NavLink to={"#"} > <i className="fa fa-linkedin" /></NavLink>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Team;
