import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import AboutCompany from "../Elements/AboutCompany";
import Team from "../Elements/Team";

var bnrimg = require("./../../images/banner/abtus-03-07.png");

class Management extends React.Component {
  render() {
    const mobileView = window.innerWidth <= 768;
    const bannerStyle = {
      backgroundImage: `url(${bnrimg})`,
      backgroundSize: "cover",
      backgroundPosition: mobileView ? "19% " : "center center",
      backgroundRepeat: "no-repeat",
    };
    return (
      <>
        <Header />
        <div className="page-content">
          <div>
            <Banner
              className="management-ban"
              title="Meet the Expert Team Behind Cheran Machines"
              pagename="About Us"
              bgimage={bnrimg}
              bannerstyle={bannerStyle}
            />
          </div>
          <Team />
          <AboutCompany />

          {/* <p>Creating quality urban lifestyles, building stronger communitie</p> */}
        </div>

        <Footer />
      </>
    );
  }
}

export default Management;
