import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Card } from "@mui/material";
import "./Testimonials.css";
const testimonials = [
  {
    image: require("./../../images/Avatar.png"),
    reviewername: "Tiruppur",
    position: "Textile Manufacturer",
    review:
      "142/3, Bharathi Street, Udayampalayam Main Road, Chinnavedampatty Post, Coimbatore - 641049. Tamil Nadu, India.",
  },
  {
    image: require("./../../images/Avatar.png"),
    reviewername: "Mumbai",
    position: "Garment Producer",
    review:
      "142/3, Bharathi Street, Udayampalayam Main Road, Chinnavedampatty Post, Coimbatore - 641049. Tamil Nadu, India.",
  },
  {
    image: require("./../../images/Avatar.png"),
    reviewername: "Delhi",
    position: "Fabric Designer",
    review:
      "142/3, Bharathi Street, Udayampalayam Main Road, Chinnavedampatty Post, Coimbatore - 641049. Tamil Nadu, India.",
  },
  {
    image: require("./../../images/Avatar.png"),
    reviewername: "kolkata",
    position: "Apparel Brand Owner",
    review:
      "142/3, Bharathi Street, Udayampalayam Main Road, Chinnavedampatty Post, Coimbatore - 641049. Tamil Nadu, India.",
  },
  {
    image: require("./../../images/Avatar.png"),
    reviewername: "Sri Lanka",
    position: "Printing Specialist",
    review:
      "142/3, Bharathi Street, Udayampalayam Main Road, Chinnavedampatty Post, Coimbatore - 641049. Tamil Nadu, India.",
  },
];

var bnr1 = require("./../../images/background/bg6.jpg");

class Testimonials3 extends React.Component {
  render() {
    const options = {
      autoplayTimeout: 8000,
      loop: true,
      autoplay: true,
      nav: false,
      smartSpeed: 2000,
      dots: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        991: {
          items: 3,
        },
      },
    };

    return (
      <>
        <div
          className="section-full mobile-page-padding p-t50 p-b30  bg-cover"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="container">
            <div className="section-content">
              {/* TITLE START */}
              <div className="section-head">
                <div className="mt-separator-outer separator-center">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-800 text-primary">Our</span>{" "}
                      Office Branches
                    </h2>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              {/* TESTIMONIAL START */}
              {/* <OwlCarousel
                className="owl-carousel testimonial-home"
                {...options}
              >
                {testimonials.map((item, index) => (
                  <div key={index} className="item">
                    <div className="testimonial-2 m-a30 hover-animation-1">
                      <div className=" block-shadow bg-white p-a30">
                        <div className="testimonial-detail clearfix">
                          <h4 className="testimonial-name m-b5 font-weight-800">
                            {item.reviewername}
                          </h4>
                        </div>
                        <div className="testimonial-text">
                          <span className="fa " />
                          <p> {item.review}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel> */}
              <div className="cardaddress">
                <div className="row">
                  <div className="col-md-6 bottomgap">
                    <div className="card cardcontainer">
                      <div className="card-body">
                        <h5 className="locationname">TIRUPUR (Dealer)</h5>
                        <span style={{ color: "black" }}>
                          D.No.34 Mullai Street, Asher Nagar, Avinashi Road,
                          Tirupur - 3
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 bottomgap">
                    <div className="card cardcontainer">
                      <div className="card-body">
                        <h5 className="locationname">MUMBAI (Dealer) </h5>
                        <span style={{ color: "black" }}>
                          A-3, 102, Sector-7, Amar Vaishali, Shanti Nagar CHS,
                          Mira Road East, Thane - 401107
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 bottomgap">
                    <div className="card cardcontainer">
                      <div className="card-body">
                        <h5 className="locationname">DELHI (Dealer)</h5>
                        <span style={{ color: "black" }}>
                          J.N.Arora & Co., P Ltd.,. Plot No: 276, FIE,
                          Patparganj Industrial Area, Delhi - 110092
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 bottomgap">
                    <div className="card cardcontainer">
                      <div className="card-body">
                        <h5 className="locationname">KOLKATA (Dealer) </h5>
                        <span style={{ color: "black" }}>
                          M/s. Nilesh Enterprises, No. 207 F, Rabindra Sarani,
                          2nd Floor, Kolkata - 700007
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row cardfinal">
                  <div className="col-md-6 bottomgap">
                    <div className="card cardcontainer cardcontainer1">
                      <div className="card-body">
                        <h5 className="locationname">SRI LANKA (Dealer)</h5>
                        <span style={{ color: "black" }}>
                          KHB Associates Pvt Ltd., No.47, Byrde Place, Colombo
                          06,Sri Lanka
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="hilite-title text-left p-l50 text-uppercase text-pop-up-top"
            style={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <strong>Clients</strong>
          </div> */}
        </div>
      </>
    );
  }
}

export default Testimonials3;
