import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Slider from "./../Elements/Slider";
// import Specialization from './../Elements/Specialization';
// import About from './../Elements/About';
import About2 from "../Elements/About2";
import OurValue from "./../Elements/OurValue";
// import OurMission from './../Elements/OurMission';
// import OurServices from './../Elements/OurServices';
// import Callus from './../Elements/Callus';
// import OurProject from './../Elements/OurProject';
// import Blogs from './../Elements/Blogs';
import Testimonials from "./../Elements/Testimonials";
import ClientsLogo from "../Elements/ClientsLogo";
import Specialization3 from "../Elements/Specialization3";
import About from "../Elements/About";
import { Helmet } from "react-helmet";

class Home extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta
            property="og:url"
            content="https://cheranmachines.zerame.com/home"
          />
          <meta name="twitter:card" content="twitter content" />

          <meta property="og:type" content="website" />

          <meta property="og:title" content=" Cheran Machines" />
          <meta property="og:description" content="home" />
          <meta
            property="og:image"
            content="https://cheranmachines.zerame.com/favicon.png"
          />
          <meta property="og:image:alt" content="Cheran Machines Logo" />
        </Helmet>

        <Header />
        <div className="page-content">
          <Slider />

          <About2 />

          {/* <Specialization />
                    <About /> */}
          <OurValue />
          {/* <Specialization3 /> */}
          <About />
          {/* <OurMission /> */}
          {/* <OurServices /> */}
          {/* <Callus /> */}
          {/* <OurProject /> */}
          {/* <Blogs /> */}
          <ClientsLogo />
          {/* <Testimonials /> */}
        </div>
        <Footer />
      </>
    );
  }
}

export default Home;
