// src/Table.js
import React from "react";

const Table = ({ data }) => {
  if (!data || !data.table_head || !data.table_body) {
    return <p>No data available</p>;
  }

  const headers = data.table_head;
  const rows = data.table_body;

  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th
              style={{
                textAlign: "center",
                backgroundColor: "#9e1975",
                color: "white",
                width: `${index == 0 ? "26%" : ""}`,
              }}
              key={index}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody style={{ textAlign: "center" }}>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {headers.map((header, cellIndex) => (
              <td key={cellIndex}>{row[header]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
