import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

var bnrimg = require("./../../images/banner/prd-01.png");

class Products extends React.Component {
  state = {
    projects: [],
    selectedProduct: null,
    isMediumScreen: window.innerWidth >= 992 && window.innerWidth <= 1200,
  };

  componentDidMount() {
    this.loadScript("./assets/js/masonary.js");

    axios
      .get("https://cheranmachines.zerame.com/api/product_list.php")
      .then((response) => {
        this.setState({ projects: response.data.responseJson });
      })
      .catch((error) => {
        console.error("Error fetching the projects:", error);
      });

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      isMediumScreen: window.innerWidth >= 992 && window.innerWidth <= 1200,
    });
  };

  loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  shareOnWhatsApp = (title, message, link) => {
    const combinedMessage = `${title}: ${message}\n${link}`;
    const encodedMessage = encodeURIComponent(combinedMessage);

    window.open(`https://wa.me/?text=${encodedMessage}`);
  };

  truncateFeature = (feature) => {
    const words = feature.split(" ");
    if (words.length > 3) {
      return words.slice(0, 4).join(" ") + "...";
    }
    return feature;
  };

  setSelectedProduct = (product) => {
    this.setState({ selectedProduct: product });
  };

  render() {
    const { selectedProduct } = this.state;
    const mobileView = window.innerWidth <= 768;
    const bannerStyle = {
      backgroundImage: `url(${bnrimg})`,
      backgroundSize: "cover",
      backgroundPosition: mobileView ? "62% " : "center center",
    };

    return (
      <>
        <Helmet>
          <meta
            property="og:url"
            content="https://cheranmachines.zerame.com/products"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Products Cheran Machines" />
          <meta property="og:description" content="Products" />
          <meta
            property="og:image"
            content={
              selectedProduct
                ? selectedProduct.product_img
                : "https://cheranmachines.zerame.com/favicon.png"
            }
          />
          <meta
            property="og:image:alt"
            content={
              selectedProduct
                ? selectedProduct.product_title_name
                : "Cheran Machines Logo"
            }
          />
        </Helmet>
        <Header />
        <div className="page-content">
          <Banner
            title="Always dedicated and devoted"
            pagename="Project Grid"
            bgimage={bnrimg}
            bannerstyle={bannerStyle}
          />
          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              <div className="mfp-gallery work-grid row clearfix">
                {this.state.projects.map((item, index) => {
                  const [firstWord, ...remainingWords] =
                    item.product_title_name.split(" ");
                  const featuresToShow = this.state.isMediumScreen
                    ? item.features.slice(0, 2)
                    : item.features.slice(0, 4);
                  return (
                    <div
                      key={index}
                      className={`masonry-item col-md-4 col-sm-6 m-b30 `}
                    >
                      <div className="image-effect-two hover-shadow">
                        <img
                          src={item.product_img}
                          alt={item.product_title_name}
                        />
                        <div className="figcaption">
                          <h4 className="mt-tilte">
                            <span style={{ color: "purple" }}>{firstWord}</span>{" "}
                            {remainingWords.join(" ")}
                          </h4>
                          <p className="font-weight-600 m-b0">Features :</p>
                          <ul className="list-simple p-l15">
                            {featuresToShow.map((feature, i) => (
                              <li key={i}>
                                {this.truncateFeature(feature.feature)}
                              </li>
                            ))}
                          </ul>
                          <ul className="icon-text-row">
                            {item.icons.map((icon, i) => (
                              <li className="icon-text-item" key={i}>
                                <div className="iconhide">
                                  <img
                                    src={icon.icon}
                                    alt={`icon-${i}`}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "40px",
                                    }}
                                  />
                                </div>
                              </li>
                            ))}
                          </ul>

                          <NavLink
                            to={
                              item.product_title_id === "16"
                                ? `/rainbowproduct/${item.product_title_id}/${item.product_title_name}`
                                : item.video_status === "1"
                                ? `/product-detail/${item.product_title_id}/${item.product_title_name}`
                                : `/product-details2/${item.product_title_id}/${item.product_title_name}`
                            }
                            className="read-more site-button btn-effect"
                          >
                            Explore More
                          </NavLink>
                          <a
                            className="mfp-link1"
                            type="button"
                            data-toggle="modal"
                            data-target="#shareModal"
                            onClick={() => this.setSelectedProduct(item)}
                          >
                            <i className="fa fa-share-alt" />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Footer />

        {/* Share Modal */}
        {this.state.selectedProduct && (
          <div
            className="modal fade"
            id="shareModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="shareModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="shareModalLabel">
                    Share {this.state.selectedProduct.product_title_name}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" style={{ color: "black" }}>
                      &times;
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="d-flex justify-content-around">
                    <FacebookShareButton
                      url={window.location.href}
                      quote={this.state.selectedProduct.product_title_name}
                      hashtag="#CheranMachines"
                    >
                      <FacebookIcon
                        size={40}
                        round
                        style={{ marginRight: "5px" }}
                      />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={window.location.href}
                      title={this.state.selectedProduct.product_title_name}
                      hashtags={["CheranMachines"]}
                    >
                      <TwitterIcon
                        size={40}
                        round
                        style={{ marginRight: "5px" }}
                      />
                    </TwitterShareButton>
                    <WhatsappShareButton
                      url={window.location.href}
                      title={this.state.selectedProduct.product_title_name}
                      onClick={() =>
                        this.shareOnWhatsApp(
                          this.state.selectedProduct.product_title_name,
                          "Check out this product at Cheran Machines:",
                          window.location.origin +
                            (this.state.selectedProduct.product_title_id ===
                            "16"
                              ? `/rainbowproduct/${
                                  this.state.selectedProduct.product_title_id
                                }/${encodeURIComponent(
                                  this.state.selectedProduct.product_title_name
                                )}`
                              : this.state.selectedProduct.video_status === "1"
                              ? `/product-detail/${
                                  this.state.selectedProduct.product_title_id
                                }/${encodeURIComponent(
                                  this.state.selectedProduct.product_title_name
                                )}`
                              : `/product-details2/${
                                  this.state.selectedProduct.product_title_id
                                }/${encodeURIComponent(
                                  this.state.selectedProduct.product_title_name
                                )}`)
                        )
                      }
                    >
                      <WhatsappIcon
                        size={40}
                        round
                        style={{ marginRight: "5px" }}
                      />
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Products;
