import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ReactPlayer from "react-player";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Table from "../Common/Table";
import Banner1 from "../Elements/Banner1";
import SimilarProjects from "../Elements/SimilarProjects";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

var bnrimg = require("./../../images/rainbow.png");

const withRouter = (Component) => {
  return (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  };
};

class ProjectDetail extends React.Component {
  state = {
    productDetails: {},
    data2: {},
    features: [],
    firstWord: "",
    restTitle: "",
    images: [],
  };

  componentDidMount() {
    const { id } = this.props.params;
    if (id) {
      console.log("Project ID:", id);

      axios
        .post("https://cheranmachines.zerame.com/api/rainbow.php", {
          id,
        })
        .then((response) => {
          const productDetails = response.data.responseJson[0];
          const table_head = productDetails.table_head || [];
          const table_body = productDetails.table_body || [];
          const images = productDetails.icons || [];

          const features = {};
          for (const [key, value] of Object.entries(productDetails.features)) {
            if (!features[key]) {
              features[key] = [];
            }
            features[key].push(...value.map((f) => f.feature));
          }

          const [firstWord, ...rest] =
            productDetails.product_title_name.split(" ");
          const restTitle = rest.join(" ");
          const data2 = { table_head, table_body };
          this.setState({
            productDetails,
            data2,
            features,
            firstWord,
            restTitle,
            images,
          });
          console.log("data table", features);
        })
        .catch((error) => {
          console.error("Error fetching product details:", error);
        });
    } else {
      console.error("No project ID found in URL params.");
    }
  }
  shareOnWhatsApp = (title, message, link) => {
    const combinedMessage = `${title}: ${message}\n${link}`;
    const encodedMessage = encodeURIComponent(combinedMessage);

    window.open(`https://wa.me/?text=${encodedMessage}`);
  };

  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: true,
    };
    return (
      <>
        <Header />
        <div className="page-content">
          <div className="section-head">
            <div
              className="text-uppercase"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "xxx-large",
                lineHeight: "normal",
              }}
            >
              <h3 className="font-weight-900 media-title">
                <span className="font-weight-900 " style={{ color: "#9e1975" }}>
                  {this.state.firstWord}{" "}
                </span>{" "}
                {this.state.restTitle}
              </h3>
              <a
                className="mfp-link1"
                type="button"
                data-toggle="modal"
                data-target="#Default-Modal"
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  fontSize: "2rem",
                  marginRight: "10px",
                  marginBottom: "10px",
                  paddingRight: "20px",
                }}
              >
                <i className="fa fa-share-alt" />
              </a>
            </div>
          </div>
          <div className="blog-post date-style-3 blog-detail text-black">
            <div className="mt-post-media clearfix m-b30">
              <ul className="grid-post">
                <li>
                  <div className="portfolio-item">
                    <img
                      className="img-responsive"
                      src={require("./../../images/rainbow.png")}
                      alt=""
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="section-full p-b40 bg-white inner-page-padding">
            <div className="container">
              <div className="section-content">
                <div
                  className="row "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="col-lg-7  col-md-7 ">
                    <div className="section-head">
                      <div className="mt-separator-outer separator-left">
                        <div
                          className="mt-separator"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h2 className="text-black text-uppercase sep-line-one ">
                            Pallet,Screen and Print Sizes
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "center",
                                backgroundColor: "#9e1975",
                                color: "white",
                              }}
                            >
                              STANDARD SIZES
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                backgroundColor: "#9e1975",
                                color: "white",
                              }}
                            >
                              PALLET SIZE
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                backgroundColor: "#9e1975",
                                color: "white",
                              }}
                            >
                              SCREEN SIZE
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                backgroundColor: "#9e1975",
                                color: "white",
                              }}
                            >
                              PRINT SIZE
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>XL</td>
                            <td>605 x 905 mm(24" x 35.6")</td>
                            <td>660 x 1040 mm (26" x 41")</td>
                            <td>500 x 700 mm (19.7" x 27.6")</td>
                          </tr>
                          <tr>
                            <td>XXL***</td>
                            <td>760 x 1250 mm (29.9" x 49.2")</td>
                            <td>850 x 1360 mm (33.5" x 53.5")</td>
                            <td>700 x 1000 mm (27.6" x 39.4")</td>
                          </tr>
                          <tr>
                            *** XXL Sizecan be print by double index printing
                            mode in XL model
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-full p-b20 bg-white mobile-page-padding">
            <div className="section-head">
              <div className="mt-separator-outer separator-center">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-900 text-primary">
                      Features
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="section-content">
                <div className="">
                  <div className="">
                    <div className="video-part-2">
                      <ul className=" p-l10">
                        {Object.entries(this.state.features).map(
                          ([category, features], index) => (
                            <div key={index} className="feature-group">
                              <div className="section-head">
                                <div className="mt-separator-outer separator-left">
                                  <div className="mt-separator">
                                    <h3
                                      className="text-black  sep-line-one font-size-600 "
                                      style={{ marginBottom: "-15px" }}
                                    >
                                      {category}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              <ul>
                                {features.map((item, idx) => (
                                  <li
                                    className="feature-right"
                                    style={{
                                      marginLeft: "10%",
                                      fontSize: "17px",
                                      marginBottom: "20px",
                                      marginRight: "5%",
                                    }}
                                    key={idx}
                                  >
                                    {item}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )
                        )}
                      </ul>
                      <ul className="icon-text-row">
                        {this.state.images.map((image, index) => (
                          <li className="icon-text-item" key={index}>
                            <div className="mt-icon-box-wraper center m-b30">
                              <div className="scale-in-center bg-moving">
                                <span className="icon-cell text-secondry">
                                  <img
                                    src={image.icon}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </span>{" "}
                                <p
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    lineHeight: "normal",
                                    fontSize: "10px",
                                    paddingTop: "10px",
                                  }}
                                >
                                  {image.feature}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-full p-b40 bg-white inner-page-padding">
            <div className="container">
              <div className="section-content">
                <div
                  className="row "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="col-lg-7  col-md-7 ">
                    <div className="section-head">
                      <div
                        className="mt-separator-outer separator-center"
                        style={{ marginBottom: "0px" }}
                      >
                        <div className="mt-separator ">
                          <h2 className="text-black text-uppercase sep-line-one mob-tit">
                            <span className="font-weight-900 text-primary">
                              Technical{" "}
                            </span>{" "}
                            Specification
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Table data={this.state.data2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SimilarProjects />

        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ReactPlayer
                  url={this.state.productDetails.product_video}
                  controls
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>

        <div id="Default-Modal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            {/* MODAL CONTENT*/}
            <div className="modal-content">
              <div
                className="modal-header bg-secondry"
                style={{ backgroundColor: "#9e1975" }}
              >
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
                <h4 className="modal-title text-white">
                  Share {this.state.productDetails.product_title_name}
                </h4>
              </div>
              <div className="modal-body">
                <FacebookShareButton
                  url={window.location.href}
                  quote={this.state.productDetails.product_title_name}
                  className="social-media-share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>{" "}
                <TwitterShareButton
                  url={window.location.href}
                  title={this.state.productDetails.product_title_name}
                  className="social-media-share-button"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>{" "}
                <WhatsappShareButton
                  url={window.location.href}
                  title={this.state.productDetails.product_title_name}
                  separator=":: "
                  onClick={() =>
                    this.shareOnWhatsApp(
                      this.state.productDetails.product_title_name,
                      "Check out this product at Cheran Machines:",
                      window.location.origin +
                        (this.state.productDetails.product_title_id === "16"
                          ? `/rainbowproduct/${
                              this.state.productDetails.product_title_id
                            }/${encodeURIComponent(
                              this.state.productDetails.product_title_name
                            )}`
                          : this.state.productDetails.video_status === "1"
                          ? `/product-detail/${
                              this.state.productDetails.product_title_id
                            }/${encodeURIComponent(
                              this.state.productDetails.product_title_name
                            )}`
                          : `/product-details2/${
                              this.state.productDetails.product_title_id
                            }/${encodeURIComponent(
                              this.state.productDetails.product_title_name
                            )}`)
                    )
                  }
                  className="social-media-share-button"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default withRouter(ProjectDetail);
