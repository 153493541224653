import React, { useState, useEffect, useRef } from "react";

const Floatbutton = () => {
  const [isHovered, setIsHovered] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsHovered(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleWhatsAppClick = (e) => {
    e.preventDefault();
    const whatsappAppUrl =
      "whatsapp://send?phone=+919843912054&text=Hello,%20Im%20looking%20for%20more%20information%20regarding%20your%20machines";
    const whatsappWebUrl =
      "https://wa.me/+919843912054?text=Hello%2C%20Im%20looking%20for%20more%20information%20regarding%20your%20machines";

    // Create an invisible iframe to check if WhatsApp is installed
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = whatsappAppUrl;
    document.body.appendChild(iframe);

    // Fallback to WhatsApp Web if the app isn't installed
    setTimeout(() => {
      document.body.removeChild(iframe);
      window.open(whatsappWebUrl, "_blank");
    }, 1000);
  };

  return (
    <div className="flot-icon" ref={menuRef}>
      <a
        href="#"
        className={`float ${isHovered ? "open" : ""}`}
        id="menu-share"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <i className={`fa ${isHovered ? "fa-times" : "fa-plus"} my-float`}></i>
      </a>
      <ul className={`menu ${isHovered ? "open" : ""}`}>
        <li>
          <a
            href="https://www.instagram.com/cheranmachines?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
          >
            <i className="fa fa-instagram my-float"></i>
          </a>
        </li>
        <li>
          <a href="#" onClick={handleWhatsAppClick} rel="noopener noreferrer">
            <i className="fa fa-whatsapp my-float"></i>
          </a>
        </li>
        <li>
          <a href="tel:+919843912054" target="_blank">
            <i className="fa fa-phone my-float"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Floatbutton;
