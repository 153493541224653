import React from "react";
import { NavLink } from "react-router-dom";

class Banner extends React.Component {
  render() {
    const { bgimage, title, bannerstyle } = this.props;
    const mobileView = window.innerWidth <= 768;
    return (
      <div
        className={`mt-bnr-inr overlay-wraper  ${
          mobileView ? "" : "bg-parallax"
        }`}
        data-stellar-background-ratio="0.5"
        style={bannerstyle}
      >
        <div className="overlay-main bg-black opacity-07" />
        <div className="container">
          <div className="mt-bnr-inr-entry">
            <div
              className="banner-back"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                padding: "20px",
              }}
            >
              <div className="banner-title-outer">
                <div className="banner-title-name">
                  <h2 className="m-b0">{title}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
