import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Navigation = ({ bgcolor = "black" }) => {
  const location = useLocation();

  // Check if current path is related to "About us"
  const isAboutActive =
    location.pathname === "/about" || location.pathname === "/Management";

  // Check if current path starts with "/project-detail"
  const isProductsActive =
    location.pathname === "/products" ||
    location.pathname.startsWith("/product-detail") ||
    location.pathname.startsWith("/product-details2") ||
    location.pathname.startsWith("/rainbowproduct");

  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }, []);

  // State to manage the submenu open/close
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleSubMenuToggle = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <div className={`header-nav navbar-collapse collapse ${bgcolor}`}>
      <ul className="nav navbar-nav">
        <li>
          <NavLink exact to="/home" activeClassName="active">
            Home
          </NavLink>
        </li>
        <li className={isAboutActive ? "active" : ""}>
          <NavLink to="/about" className={isAboutActive ? "active" : ""}>
            About us
          </NavLink>
          <ul className="sub-menu">
            <li className={location.pathname === "/about" ? "active" : ""}>
              <NavLink to="/about" activeClassName="active">
                Company Profile
              </NavLink>
            </li>
            <li className={location.pathname === "/Management" ? "active" : ""}>
              <NavLink to="/Management" activeClassName="active">
                Management Team
              </NavLink>
            </li>
          </ul>
        </li>
        <li className={isProductsActive ? "active" : ""}>
          <NavLink to="/products" activeClassName="active">
            Products
          </NavLink>
        </li>
        <li>
          <NavLink to="/contactus" activeClassName="active">
            Contact us
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Navigation;
