import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import ReactPlayer from "react-player";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../Pages/style/productdetails.css";
import SimilarProjects from "./../Elements/SimilarProjects";

var bnrimg = require("./../../images/banner/5.jpg");
var img1 = require("./../../images/video-bg2.jpg");

const images = [
  require("./../../images/gallery/portrait/pic4.jpg"),
  require("./../../images/gallery/portrait/pic5.jpg"),
  require("./../../images/gallery/portrait/pic6.jpg"),
  require("./../../images/gallery/portrait/pic7.jpg"),
  require("./../../images/gallery/portrait/pic1.jpg"),
];

const data1 = {
  features: [
    { mainfeatures: "Quick drying of the screen frames" },
    { design: ["Digital temperature control", "Timer"] },
    {
      Quality: [
        "Blower provided for distribution of hot air to the entire area.",
        "Quick drying of the screen frames",
      ],
    },
  ],
};

var bnr1 = require("./../../images/background/line.png");
class ProjectDetail extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: true,
    };
    return (
      <>
        <Header />
        <div className="page-content">
          {/* SECTION CONTENT START */}
          <h3
            style={{
              textAlign: "center",
              fontSize: "xxx-large",
              lineHeight: "normal",
            }}
          >
            <span className="font-weight-400 " style={{ color: "#9e1975" }}>
              Cheran’s{" "}
            </span>{" "}
            Micro Registration Printing Machine
          </h3>
          <div className="section-full   bg-white inner-page-padding">
            <div className="container">
              <div className="section-content ">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <div className="service-about-left">
                      <div className="mt-media">
                        <img src={require("./../../images/7.png")} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 p-t80 col-md-7">
                    <h3>Technical Specification</h3>
                    <div id="no-more-tables">
                      <table className=" table-striped table-condensed cf mt-responsive-table">
                        <thead className="cf">
                          <tr>
                            <th>Code</th>
                            <th>Company</th>
                            <th className="numeric">Price</th>
                            <th className="numeric">Change</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td data-title="Code">Code-1</td>
                            <td data-title="Company">Alfreds Futterkiste</td>
                            <td data-title="Price" className="numeric">
                              $1.38
                            </td>
                            <td data-title="Change" className="numeric">
                              -0.01
                            </td>
                          </tr>
                          <tr>
                            <td data-title="Code">Code-2</td>
                            <td data-title="Company">Alimentari Riuniti</td>
                            <td data-title="Price" className="numeric">
                              $1.15
                            </td>
                            <td data-title="Change" className="numeric">
                              +0.02
                            </td>
                          </tr>
                          <tr>
                            <td data-title="Code">Code-3</td>
                            <td data-title="Company">Ernst Handel</td>
                            <td data-title="Price" className="numeric">
                              $4.00
                            </td>
                            <td data-title="Change" className="numeric">
                              -0.04
                            </td>
                          </tr>
                          <tr>
                            <td data-title="Code">Code-4</td>
                            <td data-title="Company">Magazzini Alimentari</td>
                            <td data-title="Price" className="numeric">
                              $3.00
                            </td>
                            <td data-title="Change" className="numeric">
                              +0.06
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
            <div className="container">
              <div className="col-md-9 col-sm-8">
                <div className="m-b50">
                  {/* TITLE START */}
                  <div className="section-head">
                    <div className="mt-separator-outer separator-left">
                      <div className="mt-separator pro-tab">
                        <h2 className="text-black text-uppercase sep-line-one ">
                          <span className="font-weight-300 text-primary">
                            List
                          </span>{" "}
                          Unordered
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* TITLE END */}
                  {/* LISTING WITH NUMBERING & ALPHA */}
                  <div className="section-content">
                    {data1.features.map((featureGroup, index) => (
                      <div key={index} className="feature-group">
                        {Object.entries(featureGroup).map(
                          ([category, features]) => (
                            <div key={category} className="feature">
                              <h3>{category}</h3>
                              <ul>
                                {Array.isArray(features) ? (
                                  features.map((item, index) => (
                                    <li key={index}>{item}</li>
                                  ))
                                ) : (
                                  <li>{features}</li>
                                )}
                              </ul>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SimilarProjects />

          {/* SECTION CONTENT END  */}
        </div>

        {/* Modal */}
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ReactPlayer
                  url="https://youtu.be/TVnAcEkiEwM?feature=shared"
                  controls
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectDetail;
