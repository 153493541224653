import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import About4 from "./../Elements/About4";
import Specialization4 from "./../Elements/Specialization4";
import OurValue2 from "./../Elements/OurValue2";

// import Team3 from './../Elements/Team3';
// import AboutSummary from './../Elements/AboutSummary';
import OurMission3 from "../Elements/OurMission3";
// import AboutMeta from './AboutMeta';
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/Frame1.png");

class About extends React.Component {
  render() {
    const mobileView = window.innerWidth <= 768;
    const bannerStyle = {
      backgroundImage: `url(${bnrimg})`,
      backgroundSize: "cover",
      backgroundPosition: mobileView
        ? "35%  0px !important"
        : "center center !important",
    };
    return (
      <>
        <Helmet>
          <meta
            property="og:url"
            content="https://cheranmachines.zerame.com/about"
          />
          <meta name="twitter:card" content="twitter content" />

          <meta property="og:type" content="website" />

          <meta property="og:title" content="about Cheran Machines" />
          <meta property="og:description" content="about" />
          <meta
            property="og:image"
            content="https://cheranmachines.zerame.com/favicon.png"
          />
          <meta property="og:image:alt" content="Cheran Machines Logo" />
        </Helmet>
        <Header />
        <div className="page-content">
          <Banner
            title="Innovative Textile and Garment Printing Solutions for Your Business"
            pagename="About Us"
            bgimage={bnrimg}
            bannerstyle={bannerStyle}
          />
          <About4 />
          <Specialization4 />
          <OurMission3 />

          {/* <Team3 />
                    <AboutSummary /> */}
        </div>

        <Footer />
      </>
    );
  }
}

export default About;
