import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../Elements/Switcher";

var bgimage = require("./../../images/background/bg-site.png");

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.currentstate = {
      logo: require("./../../images/Cheran_logo_without_bg.png"),
    };
  }

  updateFooterLogo = (updatedlogo) => {
    this.currentstate.logo = updatedlogo;
  };

  render() {
    return (
      <>
        <footer
          className="site-footer footer-large	footer-wide"
          style={{ backgroundColor: "#eeeeee", color: "black" }}
        >
          <div
            className="container call-to-action-wrap bg-no-repeat bg-center"
            style={{ backgroundImage: "url(" + bgimage + ")" }}
          >
            {/* <div className="p-a30 bg-primary ">
                            <div className="row">
                                <div className="col-md-8 col-sm-8">
                                    <div className="call-to-action-left text-white">
                                        <h4 className="text-uppercase m-b10 m-t0">Stay Updated with Cheran Machines!</h4>

<span>Join our newsletter to receive the latest updates on our textile and garment printing technologies.</span>











                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <div className="call-to-action-right">
                                        <div className="widget_newsletter">
                                            <div className="newsletter-bx">
                                                <form role="search" action="./">
                                                    <div className="input-group">
                                                        <input name="news-letter" className="form-control" placeholder="ENTER YOUR EMAIL" type="text" />
                                                        <span className="input-group-btn">
                                                            <button type="submit" className="site-button"><i className="fa fa-paper-plane-o" /></button>
                                                        </span>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </div>
          {/* FOOTER BLOCKES START */}
          <div className="footer-top overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                {/* ABOUT COMPANY */}
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="widget widget_about widget-border">
                    {/*<h4 class="widget-title">About Company</h4>*/}
                    <div className="logo-footer clearfix p-b15">
                      <NavLink to={"/home"}>
                        <img
                          className="imgfooter"
                          src={this.currentstate.logo}
                          alt=""
                        />
                      </NavLink>
                    </div>
                    <p className="max-w400" style={{ lineHeight: "30px" }}>
                      Cheran Machines India Pvt Ltd. is a leading manufacturer
                      of garment and textile printing machineries. Located in
                      the heart of Coimbatore, Manchester of South India, Cheran
                      stands for its state-of-the-art equipment with superior
                      performance, strong commitment to quality and advanced
                      solutions that enhances productivity.
                      {/* Cheran Machines India Pvt. Ltd., ISO 9001 certified, is a
                      top manufacturer of garment and textile printing
                      machinery. With decades of excellence, Cheran ensures
                      superior performance and quality. Based in Coimbatore, it
                      offers advanced productivity solutions. */}
                    </p>
                    <ul className="social-icons  mt-social-links social-mb">
                      <li>
                        <a
                          href="https://www.facebook.com/cheranmachinesindia/"
                          target="_blank"
                          className="fa fa-facebook"
                        ></a>{" "}
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/cheran-machines-india-pvt-ltd/"
                          target="_blank"
                          className="fa fa-linkedin"
                        ></a>{" "}
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/cheranmachines?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                          target="_blank"
                          className="fa fa-instagram"
                        ></a>{" "}
                      </li>
                      <li>
                        <a
                          href="https://youtube.com/@cheranmachinesindiaprivate6463?si=8-DfCsPAWWPswVmG"
                          target="_blank"
                          className="fa fa-youtube"
                        ></a>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
                {/* RESENT POST */}
                <div className="col-lg-4 col-md-6 col-sm-6 text-black">
                  <div className="widget widget_address_outer">
                    <h4 className="widget-title">Contact Us</h4>

                    <ul className="widget_address">
                      {/* <li>
                        <i
                          className="fa fa-building"
                          style={{ paddingRight: "2%", color: "black" }}
                        ></i>
                        <a
                          href="http://cheranmachines.zerame.com/"
                          target="_blank"
                          className="phone-item"
                          style={{ paddingRight: "2%", color: "black" }}
                        >
                          Cheran Machines India Private Limited
                        </a>
                      </li> */}
                      <li style={{ marginBottom: "15px" }}>
                        <i
                          className="fa fa-home"
                          style={{
                            paddingRight: "2%",
                            color: "black",
                            fontSize: "18px",
                          }}
                        ></i>
                        <a
                          href="https://maps.app.goo.gl/51Rp52FvhegRPZY77?g_st=aw"
                          target="_blank"
                          className="phone-item"
                          style={{
                            paddingRight: "2%",
                            color: "black",
                            lineHeight: "30px",
                          }}
                        >
                          142/3, Bharathi Street, Udayampalayam Main Road,
                          Chinnavedampatty Post Coimbatore - 641049.Tamil Nadu,
                          India
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-envelope"></i>
                        <a
                          style={{ paddingLeft: "2%", color: "black" }}
                          href="mailto:marketing@cheranmachines.com"
                          className="phone-item"
                        >
                          marketing@cheranmachines.com
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-envelope"></i>
                        <a
                          style={{ paddingLeft: "2%", color: "black" }}
                          href="mailto:cheran@cheranmachines.com"
                          className="phone-item"
                        >
                          cheran@cheranmachines.com
                        </a>
                      </li>
                      {/* <li>
                        <i className="fa fa-envelope"></i>
                        <a
                          style={{ paddingLeft: "2%", color: "black" }}
                          href="mailto:marketing@cheranmachiens.com"
                          className="phone-item"
                        >
                         sales@cheranmachines.com
                        </a>
                      </li> */}
                      {/* <li>
                        <i
                          className="fa fa-phone"
                          style={{ fontSize: "17px" }}
                        ></i>
                        <a
                          href="tel:+919843912054"
                          className="phone-item"
                          style={{ paddingLeft: "2%", color: "black" }}
                        >
                          9843912054
                        </a>
                      </li> */}
                      {/* <li>
                        <i className="fa fa-phone"></i>
                        <a
                          href="tel:+914222667691"
                          className="phone-item"
                          style={{ paddingLeft: "2%", color: "black" }}
                        >
                          422 2667691
                        </a>
                      </li> */}
                      <li style={{ display: "flex", alignItems: "center" }}>
                        <i
                          className="fa fa-mobile "
                          style={{ fontSize: "21px" }}
                        ></i>
                        <a
                          style={{ paddingLeft: "2%", color: "black" }}
                          href="tel:+919843912054"
                          className="phone-item"
                        >
                          +91 9843912054
                        </a>
                      </li>
                      {/* <li>
                        <i className="fa fa-mobile fa-2x"></i>
                        <a
                          style={{ paddingLeft: "2%", color: "black" }}
                          href="tel:+919843912054"
                          className="phone-item"
                        >
                          +91 9843012054
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-mobile fa-2x"></i>
                        <a
                          style={{ paddingLeft: "2%", color: "black" }}
                          href="tel:+919843912054"
                          className="phone-item"
                        >
                          +91 98433-12053
                        </a>
                      </li>
                     */}
                    </ul>
                  </div>
                </div>
                {/* USEFUL LINKS */}
                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                  <div className="widget widget_services inline-links">
                    <h4 className="widget-title">Quick links</h4>
                    <ul>
                      {/* <li>
                        <NavLink to={"/about"}>About</NavLink>
                      </li> */}
                      <li>
                        <NavLink to={"/about"}>Company Profile</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/Management"}>Management Team</NavLink>
                      </li>
                      {/* <li>
                        <NavLink to={"/about"}>About</NavLink>
                      </li> */}
                      <li>
                        <NavLink to={"/products"}>Products</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/contactus"}>Contact Us</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* TAGS */}
                {/* <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="widget recent-posts-entry-date">
                                        <h4 className="widget-title">Resent Post</h4>
                                        <div className="widget-post-bx">
                                            <div className="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                                <div className="mt-post-date text-center text-uppercase text-white p-tb5">
                                                    <strong className="p-date">24</strong>
                                                    <span className="p-month">Mar</span>
                                                    <span className="p-year">2022</span>
                                                </div>
                                                <div className="mt-post-info">
                                                    <div className="mt-post-header">
                                                        <h6 className="post-title"><NavLink to={"/post-sidebar"}>On these beams, we’re building dreams.</NavLink></h6>
                                                    </div>
                                                    <div className="mt-post-meta">
                                                        <ul>
                                                            <li className="post-author"><i className="fa fa-user" />By Admin</li>
                                                            <li className="post-comment"><i className="fa fa-comments" /> 28</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                                <div className="mt-post-date text-center text-uppercase text-white p-tb5">
                                                    <strong className="p-date">30</strong>
                                                    <span className="p-month">Jan</span>
                                                    <span className="p-year">2022</span>
                                                </div>
                                                <div className="mt-post-info">
                                                    <div className="mt-post-header">
                                                        <h6 className="post-title"><NavLink to={"/post-sidebar"}>We’ll be a sensation for you next renovation</NavLink></h6>
                                                    </div>
                                                    <div className="mt-post-meta">
                                                        <ul>
                                                            <li className="post-author"><i className="fa fa-user" />By Admin</li>
                                                            <li className="post-comment"><i className="fa fa-comments" /> 29</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                {/* NEWSLETTER */}
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                <div className="mt-footer-bot-center">
                  <span className="copyrights-text">
                    © 2024{" "}
                    <a href="https://cheranmachines.zerame.com/">
                      Cheran Machines India Private Limited.
                    </a>{" "}
                    All Rights Reserved. Powered By{" "}
                    <a href="https://zerame.com/" target="blank">
                      Zerame Infotech
                    </a>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)} />
      </>
    );
  }
}

export default Footer;
