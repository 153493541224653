import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import customMarker from './../../images/favicon11.png'; // Ensure this path is correct
mapboxgl.accessToken = 'pk.eyJ1IjoibXVsaG9vbiIsImEiOiJjajVzYjJzcTUxNDExMzRtcTFvbGowaGFiIn0.DmnLTWYcsNHd8pZDEoD0jQ';

const locations = [
  { name: 'Sri Lanka', lat: 7.8731, lng: 80.7718 },
  { name: 'Bangladesh', lat: 23.6850, lng: 90.3563 },
  { name: 'Jordan', lat: 30.5852, lng: 36.2384 },
  { name: 'Qatar', lat: 25.3548, lng: 51.1839 },
  { name: 'Egypt', lat: 26.8206, lng: 30.8025 },
  { name: 'Madagascar', lat: -18.7669, lng: 46.8691 },
  { name: 'Nepal', lat: 28.3949, lng: 84.1240 },
  { name: 'Thailand', lat: 15.8700, lng: 100.9925 },
  { name: 'Ethiopia', lat: 9.1450, lng: 40.4897 },
  { name: 'Haiti', lat: 18.9712, lng: -72.2852 },
  { name: 'Tanzania', lat: -6.3690, lng: 34.8888 },
  { name: 'Uganda', lat: 1.3733, lng: 32.2903 },
  { name: 'Kenya', lat: -1.2921, lng: 36.8219 },
  { name: 'Gulf Region', lat: 25.276987, lng: 55.296249 }
];

const MapComponent = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [0, 20],
      zoom: 2,
    });

    const bounds = new mapboxgl.LngLatBounds();

    locations.forEach(location => {
      const el = document.createElement('div');
      el.className = 'custom-marker';
      el.style.backgroundImage = `url(${customMarker})`;
      el.style.width = '30px';
      el.style.height = '30px';
      el.style.backgroundSize = '100%';

      new mapboxgl.Marker(el)
        .setLngLat([location.lng, location.lat])
        .setPopup(new mapboxgl.Popup({ offset: 25 }).setText(location.name))
        .addTo(map.current);

      bounds.extend([location.lng, location.lat]);
    });

    map.current.fitBounds(bounds, {
      padding: 20
    });

  }, []);

  return (
    <div>
      <div ref={mapContainer} style={{ width: '100%', height: '500px' }} />
    </div>
  );
};

export default MapComponent;
